import React from "react";
import ContentCalendar from "../../components/Business/Calendar/ContentCalendar";
import "./ViewCalendar.scss";
import {useParams} from "react-router-dom";

function ViewCalendar() {
    const {username} = useParams();

    return (
        <div className={"viewCalendar"}>
            {(username && <ContentCalendar editable={false} username={username}/>) ||
                (<div>Impossibilité de récupérer le username</div>)
            }
        </div>
    );
}

export default ViewCalendar;