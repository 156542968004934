import React, {useContext, useState} from "react";
import "./ContentDeclarationTVA.scss";
import {EventUpdatedContext} from "../../../../store/EventUpdatedContext";
import {getIdWithToken} from "../../../../utils/storage/Token";
import {
    faBox,
    faCaretLeft,
    faCaretRight,
    faFileImage,
    faFilePdf,
    faPen,
    faPlus,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import {TypeForm} from "../../../../types/EnumTypeForm";
import FCContentModalFL, {DataForm} from "../../../FCObjets/Form/FCContentModalFL";
import FCFloatButton from "../../../FCObjets/FCFloatButton";
import FCModal from "../../../FCObjets/FCModal";
import {DeleteExpense, useFetchExpense} from "../../../../service/Business/ExpenseService";
import FCSpinner from "../../../FCObjets/FCSpinner";
import {ExpenseDBInterface, ExpenseItemInterface} from "../../../../service/Business/Interfaces/ExpenseInterface";
import FCIconButton from "../../../FCObjets/FCIconButton";
import FCAlertMsgErrorAPI from "../../../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";
import FCTag from "../../../FCObjets/FCTag";
import TypeDate from "../../../../types/TypeDate";
import ContentResumeTVA from "./ContentResumeTVA";
import {AppSettings} from "../../../../AppSettings";

function ContentDeclarationTVA() {

    let {cptUpdated, setUpdated} = useContext(EventUpdatedContext);

    const [period, setPeriod] = useState<{ month: number, year: number }>({
        month: new Date().getMonth(),
        year: new Date().getFullYear()
    })
    const [isModalOpened, setIsModalOpened] = useState(false)

    const {data: expense, loading, error} = useFetchExpense(getIdWithToken(), period.month, period.year, cptUpdated);

    const [data, setData] = useState<DataForm>({
        type: TypeForm.EMPTY,
        data: undefined,
    });

    const [confirmIsOpen] = useState(false);
    const [confirmMessage] = useState("");

    // Permettant l'ouverture du modal
    const gestionModal = (open: boolean, e: ExpenseDBInterface | any | undefined, type: TypeForm | undefined) => {
        setIsModalOpened(open)
        let t = TypeForm.EMPTY;
        if (open && type) {
            t = type
        }

        let r: DataForm = {
            type: t,
            data: e
        };

        setData(r)
    }

    const gestionPeriod = (addMonth: boolean) => {
        let month = period.month;
        let year = period.year;
        if (addMonth) {
            if (month === 11) {
                month = 0;
                year = year + 1;
            } else {
                month = month + 1;
            }
        } else {
            if (month === 0) {
                month = 11;
                year = year - 1;
            } else {
                month = month - 1;
            }
        }

        setPeriod({month: month, year: year})
    }


    return (
        <FCContentModalFL form={data} onModalFormFinished={() => {
            gestionModal(false, undefined, undefined);
            setUpdated();
        }}>
            <div className={"content-page-tva"}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center"
                }}>
                    <FCIconButton icon={faCaretLeft} onClickHandler={() => gestionPeriod(false)} tooltip={""}/>
                    <h1 className="content-heading">
                        Dépenses - {TypeDate.monthsLong[period.month] + " " + period.year}
                    </h1>
                    <FCIconButton icon={faCaretRight} onClickHandler={() => gestionPeriod(true)} tooltip={""}/>
                </div>
                {
                    !isModalOpened && <FCFloatButton items={[]} icon={faPlus} positionBottom={"70px"}
                                                     handlerOnClick={() => gestionModal(true, undefined, TypeForm.EXPENSE)}/>
                }

                {
                    expense && <ContentResumeTVA vatMonth={expense.vatMonth} vatSum={expense.vatSum}
                                                 vatDeclared={expense.vatDeclared} month={period.month}/>
                }
                {
                    loading && <div>
                        <FCSpinner size={"middle"}/>
                    </div>
                }

                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>Description dépense</th>
                        <th>Justificatif</th>
                        <th>Date</th>
                        <th>Prix TTC</th>
                        <th>Prix HT</th>
                        <th>Taux TVA</th>
                        <th>Prix TVA</th>
                        <th>
                            <div className="row-content">
                                Actions
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        loading && <tr>
                            <td><FCSpinner size={"middle"}/></td>
                        </tr>
                    }
                    {
                        expense && expense.listExpense && expense.listExpense.map((e: ExpenseItemInterface, index: React.Key) => {
                            return (<tr key={index}>
                                <td data-label="Description dépense">{e.description}</td>
                                <td data-label="Justificatif">
                                    <FCTag
                                        value={e.receiptLoaded === 1 ? "Oui" : e.receiptLoaded === 2 ? "Compte fournisseur" : "Non"}
                                        color={e.receiptLoaded === 1 ? "green" : e.receiptLoaded === 2 ? "blue" : "red"}
                                        style={{fontWeight: "bold"}}/>
                                </td>
                                <td data-label="Date">{new Date(e.date).toLocaleDateString()}</td>
                                <td data-label="Prix TTC">{e.priceInclTax.toFixed(2)}</td>
                                <td data-label="Prix HT">{e.priceExclTax.toFixed(2)} &euro;</td>
                                <td data-label="Taux TVA">{e.vat * 100} %</td>
                                <td data-label="Prix TVA">{e.priceVAT.toFixed(2)}</td>
                                <td data-label="Actions">
                                    <div className="row-content">
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            justifyItems: "left",
                                            width: "100%"
                                        }}>
                                            <FCIconButton icon={faPen} tooltip={"Modifier"} color={"green"}
                                                          onClickHandler={() => {
                                                              gestionModal(true, e, TypeForm.EXPENSE)
                                                          }}/>
                                            <FCIconButton icon={faTrash} tooltip={"Supprimer"} color={"red"}
                                                          onClickHandler={() => {
                                                              const onConfirm = () => {
                                                                  DeleteExpense(e.id).then(() => setUpdated())
                                                              }
                                                              const payload = {
                                                                  title: `Suppression de la dépense "${e.description}"`,
                                                                  msg: "Êtes-vous sûr de vouloir supprimer cette dépense ?",
                                                                  onConfirm: onConfirm
                                                              }
                                                              gestionModal(true, payload, TypeForm.CONFIRM);
                                                          }}/>
                                            {e.receiptLoaded === 0 &&
                                                <FCIconButton icon={faBox} tooltip={"Archiver Dépense"} color={"white"}
                                                              onClickHandler={() => gestionModal(true, e, TypeForm.UPLOAD_EXPENSE)}/>
                                            }

                                            {e.receiptLoaded === 1 &&
                                                <FCIconButton icon={e.nameFile.includes("pdf") ? faFilePdf : faFileImage} tooltip={"Visualiser la Dépense"}
                                                              color={"white"}
                                                              onClickHandler={() => {
                                                                  const date = new Date(e.date);
                                                                  const path = "stockage_volume/"+e.userCnx?.username + "/TVA/" + date.getFullYear() + "/" + TypeDate.monthsLong[date.getMonth()] + "/" + e.nameFile
                                                                  const url = `${AppSettings.API_ENDPOINT}${AppSettings.API_FREELANCERIE}/explorer/view/${getIdWithToken().toString()}/${encodeURIComponent(path)}`;
                                                                  window.open(url, '_blank')
                                                                  setTimeout(() => setUpdated(), 3000);
                                                              }}/>
                                            }
                                        </div>
                                    </div>
                                </td>
                            </tr>)
                        })
                    }
                    {
                        (error) && <tr>
                            <td>
                                <div>{error.message}</div>
                                <FCAlertMsgErrorAPI error={error}/>
                            </td>
                        </tr>
                    }

                    </tbody>
                </table>

            </div>
            <FCModal isOpen={confirmIsOpen} onClose={function (): void {
                throw new Error("Function not implemented.");
            }}>
                {confirmMessage}

            </FCModal>
        </FCContentModalFL>
    );
}

export default ContentDeclarationTVA;