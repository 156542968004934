import React, {useState} from "react";
import "./FCAutoCompleteInput.scss";
import FCInput from "./FCInput";

/**
 * TODO : Rendre le composant générique pour plusieurs API
 */

type FCAutoCompleteInputProps = {
    onChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
    value: string | number;
    id: string;
    type?: string;
    min?: number;
    max?: number;
    widthInput?: string;
    widthLabel?: string;
    colorTextLabel?: string;
};

const FCAutoCompleteInput: React.FunctionComponent<FCAutoCompleteInputProps> = (props: FCAutoCompleteInputProps) => {

    const [inputValue, setInputValue] = useState(props.value);
    const [options, setOptions] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1); // nouvelle propriété pour suivre l'élément sélectionné


    async function fetchOptions(inputValue: string) {
        const params = encodeURI(inputValue)
        const url = `https://api-adresse.data.gouv.fr/search/?q=${params}`;
        const headers = new Headers();
        headers.append("Accept", "application/json");

        try {
            const response = await fetch(url, {headers});
            const data = await response.json();
            let {features} = data;
            let addresses = features.map((e: { properties: { label: any; }; }) => e.properties.label);
            setOptions(addresses)
            setShowOptions(true);

        } catch (error) {
            console.error(error);
        }

    }

    function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        const inputValue = event.target.value;
        setInputValue(inputValue);
        fetchOptions(inputValue);
        if (props.onChangeHandler) {
            props.onChangeHandler(event);
        }
    }

    function selectItem(value: string) {
        setInputValue(value);
        setShowOptions(false);
        if (props.onChangeHandler) {
            const syntheticEvent = {target: {value}};
            const event = syntheticEvent as React.ChangeEvent<HTMLInputElement>;
            props.onChangeHandler(event);
        }
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        switch (event.key) {
            case "ArrowUp":
                event.preventDefault();
                setHighlightedIndex((highlightedIndex - 1 + options.length) % options.length); // passe à l'élément précédent, ou au dernier élément si nous sommes au début
                break;
            case "ArrowDown":
                event.preventDefault();
                setHighlightedIndex((highlightedIndex + 1) % options.length); // passe à l'élément suivant, ou au premier élément si nous sommes à la fin
                break;
            case "Enter":
                event.preventDefault();
                if (highlightedIndex >= 0 && highlightedIndex < options.length) {
                    // si un élément est sélectionné, le sélectionner
                    selectItem(options[highlightedIndex]);
                    setHighlightedIndex(-1); // réinitialiser la mise en évidence
                }
                break;
            case "Escape":
                setShowOptions(false); // masquer la liste déroulante
                setHighlightedIndex(-1); // réinitialiser la mise en évidence
                break;
        }
    }

    return (
        <div >
            <FCInput  {...props} value={inputValue} onChangeHandler={handleInputChange} onKeyDown={handleKeyDown}/>
            <div className="FCInputResults" style={{
                display: showOptions ? 'block' : 'none',
                width: `calc( ${props.widthInput} + 15px )`,
                minHeight:"30px",
                marginLeft:"auto",
                marginRight:"auto"

            }}>

                {options.map((value: string, index: React.Key) => (
                    <div className={`FCInputResultsItem ${highlightedIndex === index ? 'selected' : ''}`} key={index}
                         onClick={() => selectItem(value)}><p>{value}</p></div>
                ))}
            </div>
        </div>
    );
}
FCAutoCompleteInput.defaultProps = {
    type: "text",
    min: 0,
    max: 100,
    widthInput: "200px",
    widthLabel: "110px",
    colorTextLabel: undefined
};
export default FCAutoCompleteInput;