import React from "react";
import "./ContentResumeTVA.scss";
import TypeDate from "../../../../types/TypeDate";

type ContentResumeTVAProps = {
    vatMonth: number;
    vatSum: number;
    vatDeclared: number;
    month: number
};

const ContentResumeTVA: React.FunctionComponent<ContentResumeTVAProps> = (props) => {

    return (

        <div className={"resume-tva"}>

            <div className={"line-resume-tva"}>
                <div className={"col-line-resume-tva"}>
                    TVA à déduire :
                </div>
                <div className={"col-line-value-resume-tva"}>
                    {props.vatSum.toFixed(2)} €
                </div>
            </div>
            <div className={"line-resume-tva"}>
                <div className={"col-line-resume-tva"}>
                    TVA de {TypeDate.monthsLong[props.month]} :
                </div>
                <div className={"col-line-value-resume-tva"}>
                    {props.vatMonth.toFixed(2)} €
                </div>
            </div>
            <div className={"line-resume-tva"}>
                <div className={"col-line-resume-tva"}>
                    TVA à déclarer :
                </div>
                <div className={"col-line-value-resume-tva"}>
                    {props.vatDeclared.toFixed(2)} €
                </div>
            </div>
        </div>
    );
}

export default ContentResumeTVA;