import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import "./FCDatePicker.scss";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr); // register it with the name you want

type FCDatePickerProps = {
    onChangeHandler: (date: Date | null) => void;
    label: string;
    value: Date | null;
    id: string;
    widthLabel?:string;
    horizontalDisplay?:boolean
};

const FCDatePicker: React.FunctionComponent<FCDatePickerProps> = (props: FCDatePickerProps) => {
    return (
        <div className={(props.horizontalDisplay)?"horizontalFormInput":"verticalFormInput"}>
            <label className={"FCInputLabel"} htmlFor={props.id} style={{width:props.widthLabel}}>{props.label}</label>
            <DatePicker
                selected={props.value}
                onChange={props.onChangeHandler}
                dateFormat="dd/MM/yyyy" locale={"fr"}
            />
        </div>
    );
};

FCDatePicker.defaultProps = {
    widthLabel:"110px",
    horizontalDisplay:false
}

export default FCDatePicker;
