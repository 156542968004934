import { useGetRequest, RequestResponse } from "../../hook/useRequest";
import { getToken } from "../../utils/storage/Token";
import { AppSettings } from "../../AppSettings";

const useFetchDashHome = (id_user: number, forceUpdated = 0): RequestResponse => {
    const config = { headers: { Authorization: getToken() } };
    return useGetRequest(AppSettings.API_FREELANCERIE + "/dashhome/content/" + id_user, forceUpdated, config);
};

export { useFetchDashHome };
