import {
    useGetRequest,
    RequestResponse,
    PostRequest,
    DeleteRequest,
    RequestPromise,
    PutRequest,
} from "../../hook/useRequest";
import { AppSettings } from "../../AppSettings";
import { ContactInterface } from "./Interfaces/ContactInterface";
import { getToken } from "../../utils/storage/Token";

const API_URL_CONTACT = AppSettings.API_FREELANCERIE + "/contacts";

const useFetchContacts = (id_user: number, forceUpdated = 0): RequestResponse => {
    const config = { headers: { Authorization: getToken() } };
    return useGetRequest(`${API_URL_CONTACT}/${id_user.toString()}`, forceUpdated, config);
};

const UpdateContact = (id_user: number, contacts: { listContacts: ContactInterface[] }): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return PutRequest(`${API_URL_CONTACT}/${id_user.toString()}`, contacts, config);
};

const AddContact = (
    id_user: number,
    contacts: { id_customer: number; listContacts: ContactInterface[] },
): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return PostRequest(`${API_URL_CONTACT}/${id_user.toString()}`, contacts, config);
};

const DeleteContact = (id_user: number, contact: ContactInterface): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return DeleteRequest(`${API_URL_CONTACT}/${id_user.toString()}&${contact.id.toString()}`, config);
};

export { useFetchContacts, AddContact, UpdateContact, DeleteContact };
