import {createContext, useEffect, useState} from "react";

interface EventUpdatedSize {
    windowSmallSize: boolean
}

const EventUpdatedSize = createContext<EventUpdatedSize>({} as EventUpdatedSize);

const EventUpdatedSizeProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    let [windowSmallSize, setWindowSmallSize] = useState<boolean>(false);

    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () =>
            window.removeEventListener("resize",updateDimensions);
    }, [])

    const updateDimensions = () => {
        if (window.outerWidth < 1024) {
            setWindowSmallSize(true);
        }
        else {
            setWindowSmallSize(false);
        }
    }

    return (
        <EventUpdatedSize.Provider value={{windowSmallSize}}>
            {children}
        </EventUpdatedSize.Provider>

    );
};

export {EventUpdatedSizeProvider, EventUpdatedSize};