import React, {ChangeEvent, useState} from "react";
import {CallbackErrorModalFL, CallbackFinishedModalFL} from "../../Dashboard/Form/ModalForm";
import FCInput from "../../FCObjets/Form/FCInput";
import FCButton from "../../FCObjets/FCButton";
import {UploadBusiness} from "../../../service/Business/BusinessService";
import {getIdWithToken} from "../../../utils/storage/Token";
import {EnumTypeUpload} from "../../../types/Business/EnumTypeUpload";
import {UploadBilling, UploadBL} from "../../../service/Business/BillingService";
import {UploadExpense} from "../../../service/Business/ExpenseService";

type FormUploadFileProps = {
    onFinished: CallbackFinishedModalFL;
    onError: CallbackErrorModalFL;
    type: EnumTypeUpload;
    idBusiness?: number;
    idBilling?: number;
    idExpense?: number;
};

const FormUploadFile: React.FunctionComponent<FormUploadFileProps> = (props) => {
    const [file, setFile] = useState<File>();

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const handleUploadClick = () => {
        if (!file) {
            return;
        }

        let id = 0;
        if (props.type === EnumTypeUpload.ARCHIVE_BUSINESS && props.idBusiness) {
            id = props.idBusiness;

            UploadBusiness(getIdWithToken(), id, file)
                .then(() => props.onFinished("Contrat archivé !"))
                .catch((err) => props.onError(err));

        }
        else if (props.type === EnumTypeUpload.ARCHIVE_BILLING && props.idBilling) {
            id = props.idBilling;

            UploadBilling(getIdWithToken(), id, file)
                .then(() => props.onFinished("Facture archivée !"))
                .catch((err) => props.onError(err));
        }
        else if (props.type === EnumTypeUpload.ARCHIVE_BL && props.idBilling) {
            id = props.idBilling;

            UploadBL(getIdWithToken(), id, file)
                .then(() => props.onFinished("Bon de livraison archivé !"))
                .catch((err) => props.onError(err));
        }
        else if (props.type === EnumTypeUpload.ARCHIVE_EXPENSE && props.idExpense) {
            id = props.idExpense;

            UploadExpense(getIdWithToken(), id, file)
                .then(() => props.onFinished("Dépense archivée !"))
                .catch((err) => props.onError(err));
        }
    };

    return (
        <div>
            <div>
                <FCInput onChangeHandler={handleFileChange}
                         label={"Veuillez sélectionner " + (props.type === EnumTypeUpload.ARCHIVE_BUSINESS ? "un contrat" :
                             props.type === EnumTypeUpload.ARCHIVE_BL ? "un bon de livraison" :
                                 props.type === EnumTypeUpload.ARCHIVE_BILLING ? "une facture" :
                                     props.type === EnumTypeUpload.ARCHIVE_EXPENSE ? "une dépense" : "") + "."}
                         id={"fileInput"} widthInput={"400px"}
                         widthLabel={"400px"} type={"file"} value={undefined}
                         accept={props.type === EnumTypeUpload.ARCHIVE_EXPENSE ? "image/png, image/jpeg, .pdf" : ".pdf"}/>
            </div>
            <div style={{textAlign: "right"}}>
                <FCButton label={"Valider"} type={"button"} onClickHandler={handleUploadClick} style={{width: "60px"}}/>
            </div>
        </div>
    );
}

export default FormUploadFile;