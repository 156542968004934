import React from "react";
import "./FCMap.scss";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import icon from "leaflet/dist/images/marker-icon.png"
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L, {LatLngExpression} from 'leaflet';
import {AddressBookInterface} from "../../service/Business/Interfaces/AddressBookInterface";

type MapProps = {
    listMarkerAddressBook?: AddressBookInterface[];
    positionMap?: LatLngExpression;
    zoomMap?: number;
};

const FCMap: React.FunctionComponent<MapProps> = (props) => {

    L.Marker.prototype.options.icon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
        popupAnchor: [3, -41],
        iconAnchor: [10, 41],
    });

    return (
        <div style={{
            height: "100%",
            width: "100%",
        }}>
            <MapContainer
                center={props.positionMap}
                zoom={props.zoomMap}
                scrollWheelZoom={true}

                style={{
                    height: "100%",
                    width: "100%",
                }}>

                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                {
                    props.listMarkerAddressBook && props.listMarkerAddressBook.map((e: AddressBookInterface, index: React.Key) => {
                        return (
                            <Marker position={{lat: e.latitude, lng: e.longitude}} key={index}>
                                <Popup>
                                    {e.nameEntCustomer} <br/> {e.addressCustomer} <br/> {e.nameManager} <br/>
                                    <a href={'mail:' + e.mailManager}>{e.mailManager} </a> <br/>
                                    <a href={'tel:' + e.phoneManager}>{e.phoneManager}</a>
                                </Popup>
                            </Marker>)
                    })
                }
            </MapContainer>
        </div>
    );
}

FCMap.defaultProps = {
    positionMap: {lat: 43.600000, lng: 1.433333}, // Toulouse
    zoomMap: 10
}

export default FCMap;