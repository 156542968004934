import React, {useEffect, useState} from "react";
import FCInput from "../../FCObjets/Form/FCInput";
import FCButton from "../../FCObjets/FCButton";
import {BillingInterface, BillingItemInterface} from "../../../service/Business/Interfaces/BillingInterface";
import FCDropdown, {SelectOption} from "../../FCObjets/Form/FCDropdown";
import StatusBilling from "../../../utils/Business/StatusBilling";
import FCDatePicker from "../../FCObjets/Form/FCDatePicker";
import {getIdWithToken} from "../../../utils/storage/Token";
import {
    useFetchOpenBusinessDealContent
} from "../../../service/Business/BusinessService";
import FCSpinner from "../../FCObjets/FCSpinner";
import {AddBilling, UpdateBilling} from "../../../service/Business/BillingService";
import {CallbackErrorModalFL, CallbackFinishedModalFL} from "../../Dashboard/Form/ModalForm";
import FCAlertMsgErrorAPI from "../../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";
import TableBillingItem from "./Tables/TableBillingItem";
import {AddBillingItem, DeleteBillingItem, UpdateBillingItem} from "../../../service/Business/BillingItemService";

type FormBillingProps = {
    onFinished: CallbackFinishedModalFL;
    onError: CallbackErrorModalFL;
    dataBilling: BillingInterface | undefined;
};

const FormBilling: React.FunctionComponent<FormBillingProps> = ({dataBilling, onFinished, onError}) => {

    const [inputBusiness, setInputBusiness] = useState(0);
    const [inputNumber, setInputNumber] = useState(dataBilling ? dataBilling.number : "");
    const [inputState, setInputState] = useState(dataBilling ? dataBilling.state : 0);
    const [dateBegin, setDateBegin] = useState(dataBilling ? new Date(dataBilling.begin) : null);
    const [dateEnd, setDateEnd] = useState(dataBilling ? new Date(dataBilling.end) : null);

    const [optionsBusiness, setOptionsBusiness] = useState<SelectOption[] | undefined>(undefined);

    const [listBillingItemAdd, setListBillingItemAdd] = useState<BillingItemInterface[]>([]);
    const [listBillingItemDel, setListBillingItemDel] = useState<BillingItemInterface[]>([]);
    const [listBillingItemModify, setListBillingItemModify] = useState<BillingItemInterface[]>([]);

    const {data: businessContent, loading, error} = useFetchOpenBusinessDealContent(getIdWithToken());

    // Récupération de la liste des contrats
    useEffect(() => {
        if (businessContent) {
            let option: SelectOption[] = [];

            for (let i = 0; i < businessContent.length; i++) {
                option.push({value: i.toString(), label: businessContent[i].business.name});
                if (businessContent[i].business.id === dataBilling?.businessDeal.id) {
                    setInputBusiness(i);
                }
            }
            setOptionsBusiness(option);
        }
    }, [businessContent, dataBilling]);

    // Création des options pour la liste des status
    const options: SelectOption[] = []
    for (let i = 0; i < StatusBilling.ListStatus.length; i++) {
        options.push({
            value: StatusBilling.ListStatus[i].toString(),
            label: StatusBilling.toString(StatusBilling.ListStatus[i])
        });
    }

    /**
     * Gestion de l'evenement des billingitem
     * @param listAdd
     * @param listDel
     * @param listModify
     */
    const handlerEventContact = (listAdd: BillingItemInterface[], listDel: BillingItemInterface[], listModify: BillingItemInterface[]) => {
        setListBillingItemAdd(listAdd);
        setListBillingItemDel(listDel);
        setListBillingItemModify(listModify);
    }

    /**
     * Validation du formulaire
     */
    const handleSubmit = () => {
        onError(null);

        let business = dataBilling?.businessDeal;
        if (businessContent) {
            business = businessContent[inputBusiness].business;
        }

        let billing: {} = {
            begin: dateBegin?.toDateString(),
            end: dateEnd?.toDateString(),
            businessDeal: business,
            billingItems: [],
            id: dataBilling ? dataBilling.id : -1,
            number: inputNumber,
            state: inputState
        };

        // Mise à jour des billing item
        if (listBillingItemAdd.length !== 0) {
            AddBillingItem(dataBilling ? dataBilling.id : 0, {
                listBillingItem: listBillingItemAdd
            })
                .then(() => console.log('Ajout liste billingitem OK'))
                .catch((e) => console.error(e.message));
        }
        if (listBillingItemDel.length !== 0) {
            for (let i = 0; i < listBillingItemDel.length; i++) {
                DeleteBillingItem(listBillingItemDel[i])
                    .then(() => console.log('delete billingitem OK'))
                    .catch((e) => console.error(e.message));
            }
        }
        if (listBillingItemModify.length !== 0) {
            UpdateBillingItem(getIdWithToken(), {listBillingItem: listBillingItemModify})
                .then(() => console.log('Modify billingitem OK'))
                .catch((e) => console.error(e.message));
        }

        if (dataBilling) {
            UpdateBilling(dataBilling.id, billing).then(() => {
                onFinished();
            }).catch((err) => onError(err));
        } else {
            AddBilling(billing).then(() => {
                onFinished();
            }).catch((err) => onError(err));
        }
    }

    return (
        <form>
            <div>
                <React.Fragment>
                    {loading && <FCSpinner size={"small"}/>}
                    {(businessContent && optionsBusiness) &&
                        <FCDropdown onChangeHandler={(e) => setInputBusiness(parseInt(e.target.value))}
                                    options={optionsBusiness}
                                    label={"Contrat"} id={"contrat"} value={inputBusiness}
                                    widthDropdown={"311px"}/>}
                    {error &&
                        <div>
                            {error.message}
                            <FCAlertMsgErrorAPI error={error}/>
                        </div>
                    }
                </React.Fragment>
                <FCInput onChangeHandler={(e) => setInputNumber(e.target.value)} label={"Numéro"} id={"num"}
                         value={inputNumber} widthInput={"300px"} type={"text"}/>

                <FCDropdown onChangeHandler={(e) => setInputState(parseInt(e.target.value))} options={options}
                            label={"Status"} id={"Status"} value={inputState}
                            widthDropdown={"311px"}/>

                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <FCDatePicker onChangeHandler={setDateBegin} value={dateBegin} id={"deb"} label={"Début"}/>
                    <FCDatePicker onChangeHandler={setDateEnd} value={dateEnd} id={"fin"} label={"Fin"}/>
                </div>
            </div>


            {dataBilling &&
                <TableBillingItem dataItemBilling={dataBilling.billingItems} dataBilling={dataBilling}
                                  handlerEvent={handlerEventContact}/>
            }
            <div
                style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignSelf: "flex-end", alignItems: "baseline"}}>
                <FCButton label={"Valider"} type={"button"} onClickHandler={handleSubmit} style={{width: "60px"}}/>
            </div>

        </form>
    );
}

export default FormBilling;