import React, {useContext, useState} from "react";
import {TypeForm} from "../../../types/EnumTypeForm";
import FCModal from "../../FCObjets/FCModal";
import FormCustomer from "../../Business/Form/FormCustomer";
import FormBusinessDeal from "../../Business/Form/FormBusinessDeal";
import FormBilling from "../../Business/Form/FormBilling";
import FormPeriod from "../../Business/Form/FormPeriod";
import FCAlertMsgErrorAPI from "../../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";
import FormUploadFile from "../../Business/Form/FormUploadFile";
import {EnumTypeUpload} from "../../../types/Business/EnumTypeUpload";
import {EventUpdatedContext} from "../../../store/EventUpdatedContext";
import FormConfirm from "./FormConfirm";
import FormAccount from "./FormAccount/FormAccount";
import FormSendMail from "../../Business/Form/FormSendMail";
import FormDeclarationURSSAF from "../../Business/Form/FormDeclarationURSSAF";
import FormExpense from "../../Business/Form/FormExpense";

export type CallbackFinishedModalFL = (validate?: string | undefined) => void;
export type CallbackErrorModalFL = (error: any) => void;

type TypeModalForm = {
    onModalFormFinished: CallbackFinishedModalFL;
    typeForm: TypeForm;
    data: any | undefined;
}

const ModalForm: React.FunctionComponent<TypeModalForm> = ({typeForm, data, onModalFormFinished}) => {
    const {setUpdated} = useContext(EventUpdatedContext);
    const [error, setError] = useState<any>(null);

    function onFinished(textValidate: string | undefined = "", refreshData: boolean = true) {
        setError(null);
        onModalFormFinished(textValidate);

        if (refreshData) {
            setUpdated();
        }
    }

    function onError(err: any) {
        setError(err)
    }

    return (
        <div>
            <FCModal isOpen={typeForm !== TypeForm.EMPTY} onClose={() => onFinished(undefined, false)}

                     width={(typeForm === TypeForm.ACCOUNT && "600px") || undefined}
                     title={
                         (typeForm === TypeForm.CUSTOMER && "Client") ||
                         (typeForm === TypeForm.PERIOD && "Dates") ||
                         (typeForm === TypeForm.BUSINESS && "Contrat") ||
                         (typeForm === TypeForm.BILLING && "Facture") ||
                         (typeForm === TypeForm.ACCOUNT && "Configuration du compte") ||
                         (typeForm === TypeForm.SEND_MAIL && "Envoi Mail") ||
                         (typeForm === TypeForm.EXPENSE && "Dépense") ||
                         (typeForm === TypeForm.CONFIRM && data.title) ||
                         undefined}>
                {
                    (typeForm === TypeForm.CUSTOMER &&
                        <FormCustomer onFinished={onFinished} dataCustomer={data} onError={onError}/>) ||
                    (typeForm === TypeForm.BUSINESS &&
                        <FormBusinessDeal onFinished={onFinished} dataBusiness={data} onError={onError}/>) ||
                    (typeForm === TypeForm.BILLING &&
                        <FormBilling onFinished={onFinished} dataBilling={data} onError={onError}/>) ||
                    (typeForm === TypeForm.PERIOD &&
                        <FormPeriod onFinished={onFinished} dataPeriod={data} onError={onError}/>) ||
                    (typeForm === TypeForm.UPLOAD_BUSINESS &&
                        <FormUploadFile onFinished={onFinished} onError={onError} type={EnumTypeUpload.ARCHIVE_BUSINESS}
                                        idBusiness={data.id}/>) ||
                    (typeForm === TypeForm.UPLOAD_BL &&
                        <FormUploadFile onFinished={onFinished} onError={onError} type={EnumTypeUpload.ARCHIVE_BL}
                                        idBilling={data.id}/>) ||
                    (typeForm === TypeForm.UPLOAD_BILLING &&
                        <FormUploadFile onFinished={onFinished} onError={onError} type={EnumTypeUpload.ARCHIVE_BILLING}
                                        idBilling={data.id}/>) ||
                    (typeForm === TypeForm.UPLOAD_EXPENSE &&
                        <FormUploadFile onFinished={onFinished} onError={onError} type={EnumTypeUpload.ARCHIVE_EXPENSE}
                                        idExpense={data.id}/>) ||
                    (typeForm === TypeForm.CONFIRM &&
                        <FormConfirm onFinished={onFinished} onError={onError} dataConfirm={data}/>) ||
                    (typeForm === TypeForm.SEND_MAIL &&
                        <FormSendMail onFinished={onFinished} onError={onError} dataConfirm={data}/>) ||
                    (typeForm === TypeForm.EXPENSE &&
                        <FormExpense onFinished={onFinished} onError={onError} dataExpense={data}/>) ||
                    (typeForm === TypeForm.ACCOUNT &&
                        <FormAccount onFinished={onFinished} onError={onError} idUser={data}/>) ||
                    (typeForm === TypeForm.STATE_DECLARATION_URSSAF &&
                        <FormDeclarationURSSAF onFinished={onFinished} onError={onError} decURSSAF={data}/>)
                }
                {error && <FCAlertMsgErrorAPI error={error} activate={!!error}/>}

            </FCModal>

        </div>
    );
}

export default ModalForm;