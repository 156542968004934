import React from 'react';

import {useNavigate} from "react-router-dom";
import FCButton from '../../components/FCObjets/FCButton';

import './Error404.scss';
const Error404: React.FunctionComponent = () => {

    const navigate = useNavigate();
    return (
    <React.Fragment>
        <div className={"errorPage"}>
            <div className={"div_logo"}>
                <img src={ `${process.env.PUBLIC_URL}/logo_error.svg`}
                     alt={"logo_fc"} height={100}/>
                <h1>Error 404</h1>
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                <div className={"div_message"}>
                    <h3>PAGE NOT FOUND</h3>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                        <p>La page que vous tentez d'accéder n'existe pas</p>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                       <FCButton  onClickHandler={()=>navigate("/")} label="Accueil"/>
                    </div>

                </div>
            </div>
        </div>
    </React.Fragment>
 )
}

export default Error404;