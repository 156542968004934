import React from "react";
import {AppSettings} from "../../../../AppSettings";
import {getIdWithToken} from "../../../../utils/storage/Token";
import {useFetchDashHome} from "../../../../service/Business/DashHomeService";
import FCSpinner from "../../../FCObjets/FCSpinner";
import StatusBusinessDeal from "../../../../utils/Business/StatusBusinessDeal";
import "./ContentHome.scss"
import StatusBilling from "../../../../utils/Business/StatusBilling";
import {BusinessDealInterface} from "../../../../service/Business/Interfaces/BusinessDealInterface";
import {BillingInterface} from "../../../../service/Business/Interfaces/BillingInterface";
import FCAlertMsgErrorAPI from "../../../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";
import FCTag from "../../../FCObjets/FCTag";

type ContentDefaultProps = {};

const ContentHome: React.FunctionComponent<ContentDefaultProps> = () => {
    const {data: dataContentHome, loading, error} = useFetchDashHome(getIdWithToken());

    return (
        <div className={"content-page-home"}>
            <div style={{textAlign: "right"}}>
                <img src={`${AppSettings.API_ENDPOINT}/images/image/mine.gif`}
                     alt={"giphy"} style={{height: "7%", width: "7%", minHeight: "10px"}}/>
            </div>

            <h1 className="content-heading-home">Prestation en cours</h1>

            <table className={"table"} id={"table-business"}>
                <thead>
                <tr>
                    <th>Nom Client</th>
                    <th>Nom</th>
                    <th>Description</th>
                    <th>Statut</th>
                </tr>
                </thead>
                <tbody>
                {
                    loading && <tr>
                        <td><FCSpinner size={"middle"}/></td>
                    </tr>
                }
                {
                    dataContentHome && dataContentHome.businessDeals.map((e: BusinessDealInterface, index: number) => {
                        return (<tr key={index}>
                                <td data-label="Nom client">{e.customer.companyName}</td>
                                <td data-label="Nom">{e.name}</td>
                                <td data-label="Description">{e.description}</td>
                                <td data-label="Status"><FCTag value={StatusBusinessDeal.toString(e.state)}
                                                               color={StatusBusinessDeal.toColor(e.state)}
                                                               style={{fontWeight: "bold"}}/></td>
                            </tr>
                        )
                    })
                }
                {
                    error &&
                    <tr>
                        <td>
                            <div>{error.message}</div>
                            <FCAlertMsgErrorAPI error={error}/>
                        </td>
                    </tr>
                }

                </tbody>
            </table>

            <h1 className="content-heading-home"> Liste des Factures en cours </h1>
            <table className={"table"} id={"table-billing"}>
                <thead>
                <tr>
                    <th>Nom contrat</th>
                    <th>Numéro</th>
                    <th>Total TTC</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {
                    loading && <tr>
                        <td><FCSpinner size={"middle"}/></td>
                    </tr>
                }
                {
                    dataContentHome && dataContentHome.billings.map((e: BillingInterface, index: number) => {
                        let totalBilling = 0;
                        if (e.billingItems) {
                            totalBilling = e.billingItems.reduce((accumulator, bit) => {
                                return accumulator + bit.totalAmountInclTax
                            }, 0);
                        }
                        return (
                            <tr key={index}>
                                <td data-label="Nom contrat">{e.businessDeal.name}</td>
                                <td data-label="Numéro">{e.number}</td>
                                <td data-label="Total TTC">{totalBilling}</td>
                                <td data-label="Status"><FCTag value={StatusBilling.toString(e.state)}
                                                               color={StatusBilling.toColor(e.state)}
                                                               style={{fontWeight: "bold"}}/></td>
                            </tr>)
                    })
                }
                {
                    error && <tr>
                        <td>
                            <div>{error.message}</div>
                            <FCAlertMsgErrorAPI error={error}/>
                        </td>
                    </tr>
                }

                </tbody>
            </table>

        </div>
    )
}

export default ContentHome;