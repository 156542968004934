import { useGetRequest, RequestResponse, RequestPromise, PostRequest, DeleteRequest } from "../hook/useRequest";
import { getToken } from "../utils/storage/Token";
import { User } from "./Interfaces/UserInterface";

const useFetchVersion = (): RequestResponse => {
    return useGetRequest("/admin/version/");
};

const useFetchAllUsers = (id_user: number, forceUpdated = 0): RequestResponse => {
    const config = { headers: { Authorization: getToken() } };
    return useGetRequest(`/admin/users/${id_user}`, forceUpdated, config);
};

const AddUser = (user: unknown): RequestPromise => {
    return PostRequest(`/admin/users/add`, user);
};

const DeleteUser = (id_user: number, user: User): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return DeleteRequest(`/admin/users/del/${id_user.toString()}&${user.id.toString()}`, config);
};

export { useFetchVersion, useFetchAllUsers, DeleteUser, AddUser };
