import React, {useEffect, useState} from 'react';
import "./FormAccount.scss"
import FCDropdown, {SelectOption} from "../../../FCObjets/Form/FCDropdown";
import FCInput from "../../../FCObjets/Form/FCInput";
import {InterfaceCreateAccountMailing} from "../../../../views/CreateAccount/CreateAccountMailing/CreateAccountMailing";

export interface InterfaceAccountMailing {
    serverSMTP: string;
    userSmtp: string;
    passSMTP: string;
}

export type AccountMailingUserProps = {
    handlerChange: (dataMailing: InterfaceAccountMailing) => void;
    data: InterfaceAccountMailing;
    listServerSmtp: [{
        name: string;
    }]
}

const FormAccountMailing: React.FC<AccountMailingUserProps> = ({handlerChange, data, listServerSmtp}) => {

    const [dataMailing, setDataMailing] = useState<InterfaceAccountMailing>(data);

    const [optionsServer, setOptionsServer] = useState<SelectOption[] | undefined>(undefined);

    useEffect(() => {
        if (data) {
            let option: SelectOption[] = [];
            for (let i = 0; i < listServerSmtp.length; i++) {
                option.push({value: listServerSmtp[i].name, label: listServerSmtp[i].name});
            }
            setOptionsServer(option);
        }
    }, [data]);

    const eventChange = (key: "serverSMTP" | "userSmtp" | "passSMTP", value: string) => {
        let v: InterfaceCreateAccountMailing = {
            serverSMTP: dataMailing.serverSMTP,
            userSmtp: dataMailing.userSmtp,
            passSMTP: dataMailing.passSMTP,
        }
        v[key] = value;
        setDataMailing(v);
        handlerChange(v);
    }

    return (
        <React.Fragment>
            {(data) && (
                <div>
                    {optionsServer &&
                        <FCDropdown onChangeHandler={(e) => eventChange("serverSMTP", e.target.value)}
                                    options={optionsServer}
                                    label={"Serveur SMTP"} id={"server"} value={dataMailing.serverSMTP}
                                    widthDropdown={"311px"} colorTextLabel={"#FFFFFF"}
                                    widthLabel={"200px"}/>
                    }
                    <FCInput onChangeHandler={(e) => eventChange("userSmtp", e.target.value)} label={"User SMTP"}
                             id={"userSmtp"}
                             value={dataMailing.userSmtp} widthInput={"300px"} colorTextLabel={"#FFFFFF"}
                             widthLabel={"200px"}/>
                    <FCInput onChangeHandler={(e) => eventChange("passSMTP", e.target.value)} label={"Pass SMTP"}
                             id={"passSmtp"}
                             value={dataMailing.passSMTP} widthInput={"300px"} colorTextLabel={"#FFFFFF"}
                             type={"password"} widthLabel={"200px"}/>
                </div>
            )}
        </React.Fragment>
    );
};

export default FormAccountMailing;
