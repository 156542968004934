export const enum TypeStatusBusinessDeal {
    INITIALIZED = 0,
    IN_PROGRESS = 1,
    ENDED = 2,
    CANCELED = 3,
}

class StatusBusinessDeal {
    public static ListStatus = [
        TypeStatusBusinessDeal.INITIALIZED,
        TypeStatusBusinessDeal.IN_PROGRESS,
        TypeStatusBusinessDeal.ENDED,
        TypeStatusBusinessDeal.CANCELED,
    ];

    public static toString(type: TypeStatusBusinessDeal): string {
        switch (type) {
            case TypeStatusBusinessDeal.INITIALIZED:
                return "Initialisé";
            case TypeStatusBusinessDeal.IN_PROGRESS:
                return "En cours";
            case TypeStatusBusinessDeal.ENDED:
                return "Terminé";
            case TypeStatusBusinessDeal.CANCELED:
                return "Annulé";
        }
        return "Inconnue";
    }

    public static toColor(type: TypeStatusBusinessDeal): string {
        switch (type) {
            case TypeStatusBusinessDeal.INITIALIZED:
                return "yellow";
            case TypeStatusBusinessDeal.IN_PROGRESS:
                return "green";
            case TypeStatusBusinessDeal.ENDED:
                return "white";
            case TypeStatusBusinessDeal.CANCELED:
                return "red";
        }
        return "white";
    }
}

export default StatusBusinessDeal;
