import React, {useEffect, useState} from 'react';
import FCInput from "../../../components/FCObjets/Form/FCInput";
import "./CreateAccountMailing.scss";
import FCDropdown, {SelectOption} from "../../../components/FCObjets/Form/FCDropdown";

export interface InterfaceCreateAccountMailing {
    serverSMTP: string;
    userSmtp: string;
    passSMTP: string;
}

export type CreateAccountMailingProps = {
    handlerChange: (dataMailing: InterfaceCreateAccountMailing) => void;
}

const CreateAccountMailing: React.FC<CreateAccountMailingProps> = (props: CreateAccountMailingProps) => {

    const [dataMailing, setDataMailing] = useState<InterfaceCreateAccountMailing>({
        passSMTP: "",
        serverSMTP: "",
        userSmtp: ""
    });

    // TODO get API
    let optionsServer: SelectOption[] = [];
    optionsServer.push({value: "", label: "-------"});
    optionsServer.push({value: "HOTMAIL", label: "HOTMAIL"});
    optionsServer.push({value: "GMAIL", label: "GMAIL"});

    useEffect(() => {
        eventChange("serverSMTP", "HOTMAIL");
    }, []);

    const eventChange = (key: "serverSMTP" | "userSmtp" | "passSMTP", value: string) => {
        let v: InterfaceCreateAccountMailing = {
            serverSMTP: dataMailing.serverSMTP,
            userSmtp: dataMailing.userSmtp,
            passSMTP: dataMailing.passSMTP
        }
        v[key] = value;
        setDataMailing(v);
        props.handlerChange(v);
    }

    return (
        <div className="createaccountmailingview">
            <div className={"title-createaccountmailingview"}>Données de Mailling</div>
            <div className={"form-createaccountmailingview"}>
                {optionsServer &&
                    <FCDropdown onChangeHandler={(e) => eventChange("serverSMTP", e.target.value)}
                                options={optionsServer} label={"Serveur SMTP"} id={"server"}
                                value={dataMailing.serverSMTP}
                                widthDropdown={"311px"} colorTextLabel={"#FFFFFF"} widthLabel={"auto"}/>
                }
                <FCInput onChangeHandler={(e) => eventChange("userSmtp", e.target.value)}
                         label={"User SMTP"} id={"userSmtp"} value={dataMailing.userSmtp}
                         widthInput={"300px"} colorTextLabel={"#FFFFFF"} widthLabel={"auto"}/>

                <FCInput onChangeHandler={(e) => eventChange("passSMTP", e.target.value)}
                         label={"Pass SMTP"} id={"passSmtp"}
                         value={dataMailing.passSMTP} widthInput={"300px"} colorTextLabel={"#FFFFFF"}
                         type={"password"} widthLabel={"auto"}/>
            </div>
        </div>
    );
};

export default CreateAccountMailing;