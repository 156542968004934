import {
    useGetRequest,
    RequestResponse,
    PutRequest,
    PostRequest,
    RequestPromise,
    DeleteRequest,
} from "../../hook/useRequest";
import { AppSettings } from "../../AppSettings";
import { getToken } from "../../utils/storage/Token";
import { BillingInterface } from "./Interfaces/BillingInterface";

const API_URL_BILLING = AppSettings.API_FREELANCERIE + "/billing";

const useFetchBilling = (id_user: number, forceUpdated = 0): RequestResponse => {
    const config = { headers: { Authorization: getToken() } };
    return useGetRequest(`${API_URL_BILLING}/${id_user.toString()}`, forceUpdated, config);
};

const UpdateBilling = (id_billing: number, billing: unknown): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return PutRequest(`${API_URL_BILLING}/${id_billing.toString()}`, billing, config);
};

const AddBilling = (billing: unknown): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return PostRequest(API_URL_BILLING, billing, config);
};

const DeleteBilling = (billing: BillingInterface): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return DeleteRequest(`${API_URL_BILLING}/${billing.id.toString()}`, config);
};

const UploadBilling = (id_user: number, id_billing: number, file: File): RequestPromise => {
    const formData = new FormData();
    formData.append(file.name, file);
    const config = {
        headers: {
            Authorization: getToken(),
            "content-type": file.type,
        },
    };
    return PostRequest(`${API_URL_BILLING}/upload_billing/${id_user}&${id_billing}`, formData, config);
};

const UploadBL = (id_user: number, id_billing: number, file: File): RequestPromise => {
    const formData = new FormData();
    formData.append(file.name, file);
    const config = {
        headers: {
            Authorization: getToken(),
            "content-type": file.type,
        },
    };
    return PostRequest(`${API_URL_BILLING}/upload_bl/${id_user}&${id_billing}`, formData, config);
};

export { useFetchBilling, UpdateBilling, AddBilling, DeleteBilling, UploadBilling, UploadBL };
