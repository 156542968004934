import React, {useContext, useState} from "react";
import "./ContentAdminUsers.scss"
import {getIdWithToken} from "../../../../utils/storage/Token";
import {DeleteUser, useFetchAllUsers} from "../../../../service/AdminService";
import FCSpinner from "../../../FCObjets/FCSpinner";
import {AdminUserInterface} from "../../../../service/Interfaces/AdminInterface";
import FCTag from "../../../FCObjets/FCTag";
import StatusAccountUser from "../../../../utils/StatusAccountUser";
import FCIconButton from "../../../FCObjets/FCIconButton";
import {faPen, faTrash} from "@fortawesome/free-solid-svg-icons";
import FCAlertMsgErrorAPI from "../../../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";
import FCContentModalFL, {DataForm} from "../../../FCObjets/Form/FCContentModalFL";
import {TypeForm} from "../../../../types/EnumTypeForm";
import {EventUpdatedContext} from "../../../../store/EventUpdatedContext";

type ContentDefaultProps = {};

const ContentAdminUsers: React.FunctionComponent<ContentDefaultProps> = () => {

    let {cptUpdated, setUpdated} = useContext(EventUpdatedContext);
    const {data: dataUsers, loading, error} = useFetchAllUsers(getIdWithToken(), cptUpdated);

    const [data, setData] = useState<DataForm>({
        type: TypeForm.EMPTY,
        data: undefined,
    });

    // Permettant l'ouverture du modal
    const gestionModal = (open: boolean, isConfirm: boolean, e: AdminUserInterface | any | undefined) => {
        let t = TypeForm.EMPTY;
        if (open) {
            if (isConfirm) t = TypeForm.CONFIRM;
            else t = TypeForm.ACCOUNT;
        }
        setData({type: t, data: e})
    }

    return (
        <FCContentModalFL form={data} onModalFormFinished={() => gestionModal(false, false, undefined)}>
            <div className={"content-admin-users"}>

                <h1 className="content-heading-admin-users">Administration des utilisateurs</h1>

                <table className={"table"} id={"table-business"}>
                    <thead>
                    <tr>
                        <th>Nom d'utilisateur</th>
                        <th>Nom</th>
                        <th>Prénom</th>
                        <th>Email</th>
                        <th>Statut Compte</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        loading && <tr>
                            <td><FCSpinner size={"middle"}/></td>
                        </tr>
                    }
                    {
                        dataUsers && dataUsers.map((e: AdminUserInterface, index: number) => {
                            return (<tr key={index}>
                                    <td data-label="Username">{e.username}</td>
                                    <td data-label="Nom">{e.lastname}</td>
                                    <td data-label="Prénom">{e.firstname}</td>
                                    <td data-label="Email">{e.contactEmail}</td>
                                    <td data-label="Status-Compte"><FCTag value={StatusAccountUser.toString(e.stateAccount)}
                                                                          color={StatusAccountUser.toColor(e.stateAccount)}
                                                                          style={{fontWeight: "bold"}}/></td>
                                    <td data-label="Actions"><FCIconButton icon={faPen} tooltip={"Modifier"} color={"green"}
                                                                           onClickHandler={() => {
                                                                               gestionModal(true, false, e.id);
                                                                           }}/>
                                        <FCIconButton icon={faTrash} tooltip={"Supprimer"} color={"red"}
                                                      onClickHandler={() => {
                                                          const onConfirm = () => {
                                                              DeleteUser(getIdWithToken(), e).then(() => setUpdated())
                                                          }
                                                          const payload = {
                                                              title: `Suppression de ${e.username}`,
                                                              msg: "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
                                                              onConfirm: onConfirm
                                                          }
                                                          gestionModal(true, true, payload);
                                                      }}/>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    {
                        error &&
                        <tr>
                            <td>
                                <div>{error.message}</div>
                                <FCAlertMsgErrorAPI error={error}/>
                            </td>
                        </tr>
                    }

                    </tbody>
                </table>

            </div>
        </FCContentModalFL>
    )
}

export default ContentAdminUsers;