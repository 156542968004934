import React, {useState} from "react";
import FCButton from "../../FCObjets/FCButton";
import {
    DayInterface,
    PeriodInterface,
    PeriodInterfaceTPInterface
} from "../../../service/Business/Interfaces/CalendarInterface";
import FCDatePicker from "../../FCObjets/Form/FCDatePicker";
import {AddPeriod, AddPeriodTP, DeletePeriod, UpdatePeriod} from "../../../service/Business/CalendarService";
import {getIdWithToken} from "../../../utils/storage/Token";
import {CallbackErrorModalFL, CallbackFinishedModalFL} from "../../Dashboard/Form/ModalForm";
import TypePeriod from "../../../utils/Business/TypePeriod";
import FCDropdown from "../../FCObjets/Form/FCDropdown";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import FCIconButton from "../../FCObjets/FCIconButton";
import FCCheckbox from "../../FCObjets/Form/FCCheckbox";

type FormPeriodProps = {
    onFinished: CallbackFinishedModalFL;
    onError: CallbackErrorModalFL;
    dataPeriod: PeriodInterface | undefined;
};

const FormPeriod: React.FunctionComponent<FormPeriodProps> = ({dataPeriod, onFinished, onError}) => {
    const [inputDateBegin, setInputDateBegin] = useState(dataPeriod ? new Date(dataPeriod.begin) : null);
    const [inputDateEnd, setInputDateEnd] = useState(dataPeriod ? new Date(dataPeriod.end) : null);
    const [inputPeriod, setInputPeriod] = useState(dataPeriod ? dataPeriod.type : 0);
    const [inputDay, setInputDay] = useState<DayInterface>({
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true
    });


    // Création des options pour la liste des périodes
    let optionsDrop: { label: string, value: string }[] = [];
    for (let i = 0; i < TypePeriod.ListType.length; i++) {
        optionsDrop.push({
            value: TypePeriod.ListType[i].toString(),
            label: TypePeriod.toString(TypePeriod.ListType[i])
        });
    }

    /**
     * Validation du formulaire
     */
    const handleSubmit = () => {
        onError(null);

        if (dataPeriod) {
            const period: PeriodInterface = {
                begin: inputDateBegin ? inputDateBegin.toDateString() : "",
                end: inputDateEnd ? inputDateEnd.toDateString() : "",
                id: dataPeriod ? dataPeriod.id : -1,
                type: inputPeriod
            };

            UpdatePeriod(getIdWithToken(), period).then(() => {
                onFinished();
            }).catch((err) => onError(err));
        } else {
            if (!inputDay.friday || !inputDay.monday || !inputDay.wednesday || !inputDay.thursday || !inputDay.thursday) {
                const period: PeriodInterfaceTPInterface = {
                    begin: inputDateBegin ? inputDateBegin.toDateString() : "",
                    end: inputDateEnd ? inputDateEnd.toDateString() : "",
                    id: -1,
                    type: inputPeriod,
                    day: inputDay
                };
                AddPeriodTP(getIdWithToken(), period).then(() => {
                    onFinished();
                }).catch((err) => onError(err));
            }
            else {
                const period: PeriodInterface = {
                    begin: inputDateBegin ? inputDateBegin.toDateString() : "",
                    end: inputDateEnd ? inputDateEnd.toDateString() : "",
                    id: -1,
                    type: inputPeriod
                };

                AddPeriod(getIdWithToken(), period).then(() => {
                    onFinished();
                }).catch((err) => onError(err));
            }

        }
    }

    const manageInputDay = (day: number, value: boolean) => {
        let i = {...inputDay};
        if (day === 0) {
            i.monday = value;
        } else if (day === 1) {
            i.tuesday = value;
        } else if (day === 2) {
            i.wednesday = value;
        } else if (day === 3) {
            i.thursday = value;
        } else if (day === 4) {
            i.friday = value;
        }
        setInputDay(i);
    }

    return (
        <form>
            <div style={{minWidth: "350px", minHeight: "250px"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <FCDatePicker onChangeHandler={setInputDateBegin} value={inputDateBegin} id={"deb"}
                                  label={"Début :"}/>
                    <FCDatePicker onChangeHandler={setInputDateEnd} value={inputDateEnd} id={"fin"} label={"Fin :"}/>
                </div>


                <FCDropdown onChangeHandler={(e) => setInputPeriod(parseInt(e.target.value))}
                            options={optionsDrop}
                            label={"Période : "} id={"inputPeriod"} value={inputPeriod}
                            widthDropdown="100%"/>

                {
                    dataPeriod === undefined &&
                    <div>
                        <FCCheckbox onChangeHandler={(e) => manageInputDay(0, e.target.checked)}
                                    label={"Lundi"} id={"monday"}
                                    value={inputDay.monday} widthLabel={"200px"}/>
                        <FCCheckbox onChangeHandler={(e) => manageInputDay(1, e.target.checked)}
                                    label={"Mardi"} id={"tuesday"}
                                    value={inputDay.tuesday} widthLabel={"200px"}/>
                        <FCCheckbox onChangeHandler={(e) => manageInputDay(2, e.target.checked)}
                                    label={"Mercredi"} id={"wednesday"}
                                    value={inputDay.wednesday} widthLabel={"200px"}/>
                        <FCCheckbox onChangeHandler={(e) => manageInputDay(3, e.target.checked)}
                                    label={"Jeudi"} id={"thursday"}
                                    value={inputDay.thursday} widthLabel={"200px"}/>
                        <FCCheckbox onChangeHandler={(e) => manageInputDay(4, e.target.checked)}
                                    label={"Vendredi"} id={"friday"}
                                    value={inputDay.friday} widthLabel={"200px"}/>
                    </div>
                }

            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignSelf: "flex-end",
                alignItems: "baseline"
            }}>
                {
                    dataPeriod &&
                    <FCIconButton icon={faTrash} tooltip={""}
                                  onClickHandler={() => {
                                      DeletePeriod(getIdWithToken(), dataPeriod).then(() => {
                                          onFinished();
                                      })
                                  }}/>
                }

                <FCButton label={"Valider"} type={"button"} onClickHandler={handleSubmit}
                          style={{width: "60px", height: "30px", verticalAlign: "middle"}}/>
            </div>
        </form>
    );
}

export default FormPeriod;