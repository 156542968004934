import Card from "./Card";
import React from "react";
import {useParams} from "react-router-dom";
import {useFetchCards} from "../../service/CardService";
import FCSpinner from "../../components/FCObjets/FCSpinner";
import FCAlertMsgErrorAPI from "../../components/FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";
import "./CardUser.scss";

function CardUser() {

    const {username = ""} = useParams();
    const {data: card, loading, error} = useFetchCards(username);

    return (
        <div className="cardContainer" >
            <div className={"divCard"}>
                <React.Fragment>
                    {loading && <FCSpinner size={"large"}/>}
                    {card &&
                        <Card dataCard={card}/>
                    }
                    {error &&
                        <div>
                            <div>{error.message}</div>
                            <FCAlertMsgErrorAPI error={error}/>
                        </div>
                    }
                </React.Fragment>
            </div>
        </div>
    )
}

export default CardUser;