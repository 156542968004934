import React, {useContext, useState} from "react";
import "./ContentDeclarationURSSAF.scss";
import {getIdWithToken} from "../../../../utils/storage/Token";
import FCSpinner from "../../../FCObjets/FCSpinner";
import FCAlertMsgErrorAPI from "../../../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";
import {useFetchDeclarationUrssaf, useFetchEcheanceUrssaf} from "../../../../service/Business/DeclarationService";
import {DeclarationInterface} from "../../../../service/Business/Interfaces/DeclarationInterface";
import LineDeclarationURSSAF from "./LineDeclarationURSSAF";
import {EventUpdatedContext} from "../../../../store/EventUpdatedContext";
import FCIconButton from "../../../FCObjets/FCIconButton";
import {faCaretLeft, faCaretRight} from "@fortawesome/free-solid-svg-icons";

function ContentDeclarationURSSAF() {
    const [year, setYear] = useState(new Date().getFullYear())

    const {cptUpdated, setUpdated} = useContext(EventUpdatedContext);
    const {data: resumeUrssaf, loading, error} = useFetchDeclarationUrssaf(getIdWithToken(), year, cptUpdated);
    const {data: echeanceUrssaf, loading: loadingEcheance, error: errorEcheance} = useFetchEcheanceUrssaf(getIdWithToken(), year, cptUpdated);

    return (
        <div className={"content-resume-declaration"}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center"}}>
                <FCIconButton icon={faCaretLeft} onClickHandler={() => setYear(year - 1)} tooltip={""}/>
                <h1 className="content-heading">
                    Déclarations URSSAF {year}
                </h1>
                <FCIconButton icon={faCaretRight} onClickHandler={() => setYear(year + 1)} tooltip={""}/>
            </div>
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Numéro</th>
                    <th>Statut</th>
                    <th>Tot. HT</th>
                    <th>Début</th>
                    <th>Fin</th>
                    <th>
                        <div className="row-content">
                            Trimestre &nbsp;
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    (loading || loadingEcheance) && <tr>
                        <td><FCSpinner size={"middle"}/></td>
                    </tr>
                }
                {
                    (resumeUrssaf && echeanceUrssaf) && resumeUrssaf.map((decBDD: DeclarationInterface) => {
                        return decBDD.billings.map((b, index: React.Key) => {
                            return (<tr key={index}>
                                <LineDeclarationURSSAF decBDD={decBDD} billing={b}
                                                       year={year} onUpdated={() => setUpdated()}
                                                       echeancesOpen={echeanceUrssaf} />
                            </tr>)
                        });
                    })
                }
                {
                    (error || errorEcheance) && <tr>
                        <td>
                            <div>{error.message}</div>
                            <FCAlertMsgErrorAPI error={error}/>
                        </td>
                    </tr>
                }

                </tbody>
            </table>
        </div>
    );
}

export default ContentDeclarationURSSAF;