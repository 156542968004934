export const enum EnumTypeContact {
    DEFAULT = 0,
    MANAGER = 1,
    BILL = 2,
    DELIVERY_ORDER = 3,
}

class TypeContact {
    public static ListType = [
        EnumTypeContact.DEFAULT,
        EnumTypeContact.MANAGER,
        EnumTypeContact.BILL,
        EnumTypeContact.DELIVERY_ORDER,
    ];

    public static toString(type: EnumTypeContact): string {
        switch (type) {
            case EnumTypeContact.DEFAULT:
                return "Défaut";
            case EnumTypeContact.MANAGER:
                return "Manager";
            case EnumTypeContact.BILL:
                return "Facturation";
            case EnumTypeContact.DELIVERY_ORDER:
                return "Bon de livraison";
        }
        return "Inconnue";
    }
}

export default TypeContact;
