import {
    useGetRequest,
    RequestResponse,
    PostRequest,
    DeleteRequest,
    RequestPromise,
    PutRequest,
} from "../../hook/useRequest";
import { AppSettings } from "../../AppSettings";
import { CustomerInterface } from "./Interfaces/CustomerInterface";
import { getToken } from "../../utils/storage/Token";

const API_URL_CUSTOMER = AppSettings.API_FREELANCERIE + "/customers";

const useFetchCustomers = (id_user: number, forceUpdated = 0): RequestResponse => {
    const config = { headers: { Authorization: getToken() } };
    return useGetRequest(`${API_URL_CUSTOMER}/${id_user.toString()}`, forceUpdated, config);
};

const UpdateCustomer = (id_user: number, customer: CustomerInterface): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return PutRequest(`${API_URL_CUSTOMER}/${id_user.toString()}&${customer.id.toString()}`, customer, config);
};

const AddCustomer = (id_user: number, customer: CustomerInterface): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return PostRequest(`${API_URL_CUSTOMER}/${id_user.toString()}`, customer, config);
};

const DeleteCustomer = (id_user: number, customer: CustomerInterface): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return DeleteRequest(`${API_URL_CUSTOMER}/${id_user.toString()}&${customer.id.toString()}`, config);
};

export { useFetchCustomers, AddCustomer, UpdateCustomer, DeleteCustomer };
