import React from "react";
import {CallbackErrorModalFL, CallbackFinishedModalFL} from "./ModalForm";
import {faCancel, faCheck} from "@fortawesome/free-solid-svg-icons";
import FCIconButton from "../../FCObjets/FCIconButton";

type FormConfirmProps = {
    onFinished: CallbackFinishedModalFL;
    onError: CallbackErrorModalFL;
    dataConfirm: any | undefined;
};

const FormConfirm: React.FunctionComponent<FormConfirmProps> = ({dataConfirm, onFinished, onError}) => {


    return (
        <form>

            {
                dataConfirm.msg &&<p>{dataConfirm.msg}</p>
            }
            <div style={{display:"flex", flexDirection:"row", width:"100%",justifyContent:"space-between",alignItems:"baseline"}}>
                <FCIconButton icon={faCancel} tooltip={"Annuler"} color="red" onClickHandler={() => {
                    if(dataConfirm.onCanceled){
                        dataConfirm.onCanceled();
                    }
                    onFinished();
                }}/>
                <FCIconButton icon={faCheck} tooltip={"Confirmer"} color="green" onClickHandler={() => {
                    if(dataConfirm.onConfirm){
                        dataConfirm.onConfirm();
                        onFinished();
                    }else{
                        onError("Pas d'évènement de confirmation")
                    }
                }}/>
            </div>
        </form>
    );
}

export default FormConfirm;