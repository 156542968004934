import React from "react";
import {
    CalendarInterface,
    EnumTypeEvent,
    EventsInterface,
    PeriodInterface
} from "../../../service/Business/Interfaces/CalendarInterface";
import {AppSettings} from "../../../AppSettings";
import FCTooltip from "../../FCObjets/FCTooltip";

type CalendarDayProps = {
    forceText: string | number | undefined;
    date: Date | undefined;
    title: boolean;
    editable: boolean;

    hide: boolean;
    datasBDD: CalendarInterface;

    onEventClick: (data: PeriodInterface) => void;
};

function CalendarDay(props: CalendarDayProps) {

    let text: string = "";
    if (props.date !== undefined) {
        text = props.date.getDate().toString();
        if (props.forceText !== undefined && props.forceText !== "") {
            text = props.forceText.toString();
        }
    }

    type typeDataStyle = {
        style: {
            backgroundColor: string;
            color: string;
            cursor: string;
        },
        tooltip1: string;
        tooltip2: string;
        dataPeriod: PeriodInterface | undefined;
    }

    function isSameDay(d1: Date, d2: Date): boolean {
        return (d1.getDate() === d2.getDate() &&
            d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth())
    }

    function getColorFromEvent(e: EventsInterface) {
        switch (e.type) {
            case EnumTypeEvent.CRITIC:
                return AppSettings.Freelancerie.Calendar.COLOR_CRITIC;
            case EnumTypeEvent.FERIE:
                return AppSettings.Freelancerie.Calendar.COLOR_FERIE;
            case EnumTypeEvent.HOLIDAY:
                return AppSettings.Freelancerie.Calendar.COLOR_HOLIDAY
            case EnumTypeEvent.WORKED:
                return AppSettings.Freelancerie.Calendar.COLOR_WORKED;
            case EnumTypeEvent.WEEKEND:
                return AppSettings.Freelancerie.Calendar.COLOR_WEEKEND;
            case EnumTypeEvent.URSSAF:
                return AppSettings.Freelancerie.Calendar.COLOR_URSSAF;

            default:
                return "";
        }
    }

    /**
     * Gestion de l'événement de click
     */
    function eventClick(dataStyle: typeDataStyle): void {
        if (dataStyle.style.cursor === "pointer" && dataStyle.dataPeriod) {
            props.onEventClick(dataStyle.dataPeriod);
        }
    }

    /**
     * Création du style pour un jour particulier
     * Réalisé de façon générique pour l'ensemble des journées
     */
    function getStyleFromDay(): typeDataStyle {

        if (props.date === undefined) {
            return {
                style: {backgroundColor: "", color: "", cursor: "default"},
                tooltip1: "",
                tooltip2: "",
                dataPeriod: undefined
            };
        }

        let currentDay = new Date();
        if (isSameDay(currentDay, props.date)) {
            return {
                style: {
                    backgroundColor: AppSettings.Freelancerie.Calendar.COLOR_CURRENT_DAY,
                    color: "#ffffff",
                    cursor: "default"
                },
                tooltip1: "Aujourd'hui", tooltip2: "", dataPeriod: undefined
            }
        }
        // Pour chaque évènement
        let calendarObject: typeDataStyle = {
            style: {backgroundColor: "", color: "", cursor: "default"},
            tooltip1: "",
            tooltip2: "",
            dataPeriod: undefined
        }
        let hasEvent = false;
        for (let event of props.datasBDD.listEvents) {
            const begin = new Date(event.begin);
            const end = new Date(event.end);

            if (begin <= props.date && props.date <= end) {
                hasEvent = true;

                if (props.date.getDay() === 0 || props.date.getDay() === 6) {

                    calendarObject = {
                        style: {
                            backgroundColor: calendarObject.style.backgroundColor === AppSettings.Freelancerie.Calendar.COLOR_FERIE ||
                            calendarObject.style.backgroundColor === AppSettings.Freelancerie.Calendar.COLOR_CRITIC ||
                            calendarObject.style.backgroundColor === AppSettings.Freelancerie.Calendar.COLOR_URSSAF ? calendarObject.style.backgroundColor :
                                (getColorFromEvent(event) === AppSettings.Freelancerie.Calendar.COLOR_CRITIC ||
                                getColorFromEvent(event) === AppSettings.Freelancerie.Calendar.COLOR_FERIE ||
                                getColorFromEvent(event) === AppSettings.Freelancerie.Calendar.COLOR_URSSAF) ? getColorFromEvent(event) :
                                AppSettings.Freelancerie.Calendar.COLOR_WEEKEND,
                            color: "#ffffff",
                            cursor: props.editable ? "pointer" : "default"
                        },
                        tooltip1: calendarObject.tooltip1,
                        tooltip2: calendarObject.tooltip2,
                        dataPeriod: undefined
                    }
                }

                calendarObject = {
                    style: {
                        //On met une priorité sur le jour critique
                        backgroundColor:
                            calendarObject.style.backgroundColor === AppSettings.Freelancerie.Calendar.COLOR_CRITIC ? calendarObject.style.backgroundColor :
                                calendarObject.style.backgroundColor === AppSettings.Freelancerie.Calendar.COLOR_URSSAF ? calendarObject.style.backgroundColor :
                                    calendarObject.style.backgroundColor === AppSettings.Freelancerie.Calendar.COLOR_FERIE ? calendarObject.style.backgroundColor :
                                        calendarObject.style.backgroundColor === AppSettings.Freelancerie.Calendar.COLOR_WORKED ? calendarObject.style.backgroundColor :
                                            calendarObject.style.backgroundColor === AppSettings.Freelancerie.Calendar.COLOR_WEEKEND ? calendarObject.style.backgroundColor :
                                                calendarObject.style.backgroundColor === AppSettings.Freelancerie.Calendar.COLOR_HOLIDAY ? calendarObject.style.backgroundColor :
                                                    getColorFromEvent(event),
                        color: "#ffffff",
                        cursor: props.editable ? "pointer" : "default"
                    },
                    tooltip1: event.title + " " + calendarObject.tooltip1,
                    tooltip2: event.description + " " + calendarObject.tooltip2,
                    dataPeriod: event.period
                }
            }
        }
        if (hasEvent) {
            return calendarObject;
        } else {
            if (props.date.getDay() === 0 || props.date.getDay() === 6) {
                calendarObject = {
                    style: {
                        backgroundColor: AppSettings.Freelancerie.Calendar.COLOR_WEEKEND,
                        color: "#ffffff",
                        cursor: props.editable ? "pointer" : "default"
                    },
                    tooltip1: "Weekend ! " + calendarObject.tooltip1,
                    tooltip2: " " + calendarObject.tooltip2,
                    dataPeriod: undefined
                }
            }
        }
        return calendarObject;
    }

    const dataDay: typeDataStyle = getStyleFromDay();

    return (
        <div onClick={() => eventClick(dataDay)}>
            {(props.forceText &&
                    <div className={"day"} style={props.title ? {fontWeight: 700} : {}}>
                        {props.forceText}
                    </div>)
                ||
                (<div>
                    <div
                        style={!props.hide ? dataDay.style : {border: "0px"}}
                        className={"tooltip day"}>
                        {props.hide ? "" : text}
                        {(dataDay.tooltip1 || dataDay.tooltip2) &&
                            <FCTooltip>
                                <div>{dataDay.tooltip1}</div>
                                <div>{dataDay.tooltip2}</div>
                            </FCTooltip>
                        }
                    </div>
                </div>)
            }
        </div>
    );
}

CalendarDay.defaultProps = {
    forceText: undefined,
    date: undefined,
    title: false,
    hide: false,
}

export default CalendarDay;