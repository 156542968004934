declare global {
    interface Window {
        ENV: any;
    }
}
export class AppSettings {
    public static API_ENDPOINT =
        window.ENV == "ENV" ? process.env.REACT_APP_API_ENDPOINT : window.ENV.REACT_APP_API_ENDPOINT;
    public static API_CV = "/cv";
    public static API_FREELANCERIE = "/fl";

    public static Freelancerie = {
        Calendar: {
            COLOR_HOLIDAY: "#706fd399",
            COLOR_WORKED: "#22a6b399",
            COLOR_FERIE: "#fbc53199",
            COLOR_CRITIC: "#ff7675",
            COLOR_CURRENT_DAY: "#74b9ff",
            COLOR_WEEKEND: "#ced6e066",
            COLOR_URSSAF: "#833471",
        },
    };
}
