import {createContext, useState} from "react";

interface EventUpdatedContextData {
    cptUpdated: number
    setUpdated: () => void
}

const EventUpdatedContext = createContext<EventUpdatedContextData>({} as EventUpdatedContextData);

const EventUpdatedProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    let [cptUpdated, setCptUpdated] = useState(0);
    const setUpdated = () => {
        setCptUpdated(++cptUpdated);
    }
    return (
        <EventUpdatedContext.Provider value={{cptUpdated, setUpdated}}>
            {children}
        </EventUpdatedContext.Provider>

    );
};

export {EventUpdatedProvider, EventUpdatedContext};