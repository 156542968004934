import React, { useEffect, useState, useRef } from "react";
import FCButton from "../../FCObjets/FCButton";
import {
  CallbackErrorModalFL,
  CallbackFinishedModalFL,
} from "../../Dashboard/Form/ModalForm";
import {
  GetMailContent,
  RequestMail,
} from "../../../service/Business/EmailService";
import { getIdWithToken } from "../../../utils/storage/Token";
import FCInput from "../../FCObjets/Form/FCInput";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import FCAlertMsgErrorAPI from "../../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";
import FCAlertMsg, { TypeAlertMsg } from "../../FCObjets/FCAlertMsg/FCAlertMsg";
import FCSpinner from "../../FCObjets/FCSpinner";

type FormSendMailProps = {
  onFinished: CallbackFinishedModalFL;
  onError: CallbackErrorModalFL;
  dataConfirm: any;
  onSuccess?: Function;
};

const FormSendMail: React.FunctionComponent<FormSendMailProps> = (
  props: FormSendMailProps
) => {
  const [errorMail, setErrorMail] = useState<any>(null);
  const [loadingMail, setLoadingMail] = useState(false);
  const [mailSubject, setMailSubject] = useState("");
  const [mailContent, setMailContent] = useState("");
  const [retMail, setRetMail] = useState<any>(null);
  const [retMsg, setRetMsg] = useState("");
  const editorRef = useRef<TinyMCEEditor | null>(null);

  useEffect(() => {
    GetMailContent(
      getIdWithToken(),
      props.dataConfirm.typeMail,
      props.dataConfirm.id
    )
      .then((ret) => {
        setRetMail(ret.data);
        setMailContent(ret.data.html);
        setMailSubject(ret.data.subject);
      })
      .catch((e) => {
        setErrorMail(e);
      })
      .finally(() => {
        setLoadingMail(false);
      });
  }, []);

  const sendMail = () => {
    setLoadingMail(true);
    setErrorMail(null);
    setRetMsg("");
    const mail = {...retMail,html:mailContent,subject:mailSubject}
    setRetMail(mail)
    RequestMail(
      getIdWithToken(),
      props.dataConfirm.typeMail,
      props.dataConfirm.id,
     mail
    )
      .then((ret) => {
        const msg = "Mail envoyé ! Liste destinataire [" + ret.data.listReceiver + "]"
        setRetMsg(msg);
        if (props.onSuccess) {
          props.onFinished();
        }
      })
      .catch((e) => {
        setErrorMail(e);
      })
      .finally(() => {
        setLoadingMail(false);
      });
  };

  const copyMailToClipboard = () =>{
    setLoadingMail(true);
    let content = new Blob([ mailContent ], { type: 'text/html' })
    navigator.clipboard.write([new ClipboardItem({ [ content.type ]: content })])
    .then(()=>{
      setRetMsg("Mail copié dans le presse papier");
    })
    .finally(()=>{
      setLoadingMail(false);
    });
  };

  return (
    <React.Fragment>
      <form>
        <p>Mail Pour :{retMail ? retMail.listReceiver : ""} </p>
        <FCInput
          label={"Objet"}
          value={mailSubject}
          widthInput="500px"
          onChangeHandler={(e)=>{
          setMailSubject(e.target.value)}}
          id={""}
        ></FCInput>
        <div style={{ minWidth: "350px", minHeight: "250px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Editor
              tinymceScriptSrc={
                process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
              }
              onInit={(_, editor) => (editorRef.current = editor)}
              value={mailContent}
              onEditorChange={(newValue, _) => setMailContent(newValue)}
              init={{
                width: 500,
                menubar: false,
                toolbar:
                  "undo redo | formatselect | paste " +
                  "bold italic alignleft aligncenter " +
                  "alignright alignjustify ",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignSelf: "flex-end",
            alignItems: "baseline",
          }}
        >
          {!loadingMail && (
            <React.Fragment>
              <FCButton
                label={"Copier"}
                type={"button"}
                style={{ width: "60px", height: "30px", verticalAlign: "middle", marginRight:20 }}
                onClickHandler={() => copyMailToClipboard()}
              />
              <FCButton
                label={"Envoyer"}
                type={"button"}
                style={{ width: "60px", height: "30px", verticalAlign: "middle" }}
                onClickHandler={() => sendMail()}
              />
            </React.Fragment>
          )}
          {loadingMail && (
            <div className={"iconButton tooltip"}>
              <FCSpinner size={"small"} dark={true} />
            </div>
          )}
        </div>
      </form>
      {errorMail && (
        <FCAlertMsgErrorAPI error={errorMail} activate={errorMail} />
      )}

      {retMsg !== "" && (
        <FCAlertMsg
          textMsg={retMsg}
          type={TypeAlertMsg.SUCCESS}
          activate={retMsg !== ""}
        />
      )}
    </React.Fragment>
  );
};

export default FormSendMail;
