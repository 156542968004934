import React, {useState} from "react";
import "./FCToggleSwitch.scss";

type ToggleSwitchProps = {
    onToggle: (isChecked: boolean) => void;
    defaultChecked?: boolean;
    label:string;
};

const FCToggleSwitch: React.FunctionComponent<ToggleSwitchProps> = ({onToggle, defaultChecked = false, label}) => {

    const [isChecked, setIsChecked] = useState(defaultChecked);

    const handleToggle = () => {
        const newValue = !isChecked;
        setIsChecked(newValue);
        onToggle(newValue);
    };

    return (
        <div className={"divToggleSwitch"}>

            <label className={"labelFCToggleSwitch"}>{label}</label>
            <div className={"divToggle"}>
            <label className="switch">
                <input type="checkbox"
                       checked={isChecked}
                       onChange={handleToggle}/>
                <span className="slider round"></span>
            </label>
            </div>
        </div>
    );

}

export default FCToggleSwitch;