import React, {useState} from 'react';

import './CreateAccount.scss';
import CreateAccountUser, {InterfaceCreateAccountUser} from './CreateAccountUser/CreateAccountUser';
import FCButton from "../../components/FCObjets/FCButton";
import CreateAccountEnterprise, {
    InterfaceCreateAccountEnterprise
} from "./CreateAccountEnterprise/CreateAccountEnterprise";
import CreateAccountMailing, {InterfaceCreateAccountMailing} from "./CreateAccountMailing/CreateAccountMailing";
import {AddUser} from "../../service/AdminService";
import jwt from "jsonwebtoken";
import {useNavigate} from "react-router-dom";
import FCAlertMsg, {TypeAlertMsg} from "../../components/FCObjets/FCAlertMsg/FCAlertMsg";

function CreateAccount() {

    const [indexComponent, setIndexComponent] = useState(0);
    const [dataMailing, setDataMailing] = useState<InterfaceCreateAccountMailing>();
    const [dataUser, setDataUser] = useState<InterfaceCreateAccountUser>();
    const [dataEnterprise, setDataEnterprise] = useState<InterfaceCreateAccountEnterprise>();

    const [verifyInput, setVerifyInput] = useState(false);
    const [errorInput, setErrorInput] = useState<string | undefined>(undefined);
    const navigate = useNavigate();

    const NumberMaxComponent: number = 3;

    const submit = () => {

        // Coloration des inputs manquants
        setVerifyInput(true);

        if (!dataUser || !dataEnterprise || !dataMailing) {
            setErrorInput("Des données sont manquantes (data empty)");
            return;
        }

        if (!dataUser.firstname || !dataUser.lastname || !dataUser.username || !dataUser.password || !dataUser.civility ||
            !dataEnterprise.tjm || !dataEnterprise.companyName || !dataEnterprise.companySiret || !dataEnterprise.companyLocation ||
            !dataEnterprise.declarationLocation) {
            if(!dataUser.username) {
                setErrorInput("Des données sont manquantes, ou username déjà existant");
            }
            else {
                setErrorInput("Des données sont manquantes");
            }
            return;
        }

        const user: any = {
            user: {
                firstname: dataUser.firstname,
                lastname: dataUser.lastname,
                username: dataUser.username,
                password: jwt.sign({password: dataUser.password}, "privateKeyPass"),
                civility: dataUser.civility,
                userInfo: {
                    userSMTP: dataMailing.userSmtp,
                    nameServerSMTP: dataMailing.serverSMTP,
                    passSMTP: jwt.sign({passSMTP: dataMailing.passSMTP}, "privateKeyPassSMTP"),
                    tjmDefault: dataEnterprise.tjm,
                    companyName: dataEnterprise.companyName,
                    companySiret: dataEnterprise.companySiret,
                    companyLocation: dataEnterprise.companyLocation,
                    declarationLocation: dataEnterprise.declarationLocation,
                    titleJob: dataUser.titleJob,
                    urlCV: dataUser.urlCV
                }
            }
        }
        AddUser(user).then(() => navigate('wait_validate')).catch((e) => console.error(e));
    }

    const toggleSecondView = (nextComponent: boolean) => {
        setErrorInput("");

        if (nextComponent && indexComponent + 1 <= NumberMaxComponent - 1) {
            setIndexComponent(indexComponent + 1);
        }
        if (!nextComponent && indexComponent - 1 >= 0) {
            setIndexComponent(indexComponent - 1);
        }
    };

    return (
        <React.Fragment>
            <div className={"showview-yellow"}>
            <div className={"showview"}>
                <div className="vertical-line">
                    {
                        indexComponent !== 0 &&
                        <div className={"little_dot first-dot"} onClick={() => setIndexComponent(0)}/>
                    }
                    {
                        indexComponent !== 1 &&
                        <div className={"little_dot second-dot"} onClick={() => setIndexComponent(1)}/>
                    }
                    {
                        indexComponent !== 2 &&
                        <div className={"little_dot thirty-dot"} onClick={() => setIndexComponent(2)}/>
                    }
                    <div
                        className={`dot ${indexComponent === 0 ? 'first-dot' : indexComponent === 1 ? 'second-dot' : 'thirty-dot'}`}/>
                </div>

                <div className="showview-content">
                    <div>
                        <div className={`first-view ${indexComponent !== 0 ? 'fade-out' : ''}`}>
                            <CreateAccountUser verifyInput={verifyInput}
                                               handlerChange={(dataUser) => setDataUser(dataUser)}/>
                        </div>
                        <div className={`second-view ${indexComponent === 1 ? 'fade-in' : 'fade-out'}`}>
                            <CreateAccountEnterprise verifyInput={verifyInput}
                                                     handlerChange={(dataEnterprise) => setDataEnterprise(dataEnterprise)}/>
                        </div>
                        <div className={`thirty-view ${indexComponent === 2 ? 'fade-in' : ''}`}>
                            <CreateAccountMailing handlerChange={(dataMailing) => setDataMailing(dataMailing)}/>
                        </div>
                    </div>
                    <div className={"divButton"}>
                        {errorInput &&
                            <div className={"textError"}>
                                <FCAlertMsg textMsg={errorInput} type={TypeAlertMsg.ERROR} activate={!!errorInput}/>
                            </div>
                        }
                        {indexComponent !== 0 &&
                            <div style={{marginRight: "15px"}}>
                                <FCButton label={"Précédent"} onClickHandler={() => toggleSecondView(false)}/>
                            </div>
                        }
                        {indexComponent < NumberMaxComponent - 1 &&
                            <FCButton label={"Suivant"} onClickHandler={() => toggleSecondView(true)}/>
                        }
                        {indexComponent === NumberMaxComponent - 1 &&
                            <FCButton label={"Terminé"} onClickHandler={submit}/>
                        }
                    </div>
                </div>
            </div>
            </div>
        </React.Fragment>
    );
}

export default CreateAccount;