import React, {ReactNode, useEffect, useRef, useState} from "react";
import "./FCModal.scss"

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    width?: string;
    title?: string;
}

const FCModal: React.FC<ModalProps> = ({isOpen, onClose, width, title, children}) => {
    const [isTransitioning, setIsTransitioning] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);

    const close = () =>{
        setIsTransitioning(true);
        setTimeout(() => {
            setIsTransitioning(false);
            onClose();
        }, 300);
    }
    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
               close();
            }
        };
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [onClose]);

    return isOpen ? (
        <div className={`modal${isTransitioning ? " transitioning" : ""}`}>
            <div className="modal-window" style={{width}} ref={modalRef}>
                <div className="modal-heading">
                    <div className="modal-close" onClick={()=>close()}>&#10006;</div>

                </div>
                <div className="modal-body">
                    {title && <div className="modal-title">{title}</div>}
                    {children}
                </div>
            </div>
            <div className="modal-overlay"></div>

        </div>) : null;
};

export default FCModal;