import { GetRequest, PostRequest, RequestPromise } from "../../hook/useRequest";
import { AppSettings } from "../../AppSettings";
import { getToken } from "../../utils/storage/Token";
import { EnumTypeMail } from "../../types/Business/EnumTypeMail";
import { EmailFCInterface } from "../Interfaces/EmailFCInterface";

const API_URL_MAIL = AppSettings.API_FREELANCERIE + "/mail";

const RequestMail = (
    id_user: number,
    typeMail: EnumTypeMail,
    id: number,
    mailContent: EmailFCInterface,
): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };

    let url = `${API_URL_MAIL}/`;
    if (typeMail === EnumTypeMail.BL) {
        url += "bl";
    } else if (typeMail === EnumTypeMail.BUSINESS) {
        url += "business";
    } else if (typeMail === EnumTypeMail.BILLING) {
        url += "billing";
    }
    const endpoint_url = `${url}/${id_user.toString()}&${id.toString()}`;
    return PostRequest(endpoint_url, mailContent, config);
};

const GetMailContent = (id_user: number, typeMail: EnumTypeMail, id: number): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };

    let url = `${API_URL_MAIL}/`;
    if (typeMail === EnumTypeMail.BL) {
        url += "bl";
    } else if (typeMail === EnumTypeMail.BUSINESS) {
        url += "business";
    } else if (typeMail === EnumTypeMail.BILLING) {
        url += "billing";
    }
    const endpoint_url = `${url}/${id_user.toString()}&${id.toString()}`;
    return GetRequest(endpoint_url, config);
};

export { RequestMail, GetMailContent };
