import {
    useGetRequest,
    RequestResponse,
    PostRequest,
    DeleteRequest,
    RequestPromise,
    PutRequest,
} from "../../hook/useRequest";
import {AppSettings} from "../../AppSettings";
import {getToken} from "../../utils/storage/Token";
import {ExpenseDBInterface} from "./Interfaces/ExpenseInterface";

const API_URL_EXPENSE = AppSettings.API_FREELANCERIE + "/expense";

const useFetchExpense = (idUser: number, month: number, year: number, forceUpdated = 0): RequestResponse => {
    const config = {headers: {Authorization: getToken()}};
    return useGetRequest(`${API_URL_EXPENSE}/${idUser.toString()}&${month.toString()}&${year.toString()}`, forceUpdated, config);
};

const UpdateExpense = (idExpense: number, expense: ExpenseDBInterface): RequestPromise => {
    const config = {headers: {Authorization: getToken()}};
    return PutRequest(`${API_URL_EXPENSE}/${idExpense.toString()}`, expense, config);
};

const AddExpense = (idUser: number, expense: ExpenseDBInterface): RequestPromise => {
    const config = {headers: {Authorization: getToken()}};
    return PostRequest(`${API_URL_EXPENSE}/${idUser.toString()}`, expense, config);
};

const DeleteExpense = (idExpense: number): RequestPromise => {
    const config = {headers: {Authorization: getToken()}};
    return DeleteRequest(`${API_URL_EXPENSE}/${idExpense.toString()}`, config);
};

const UploadExpense = (idUser: number, idExpense: number, file: File): RequestPromise => {
    const formData = new FormData();
    formData.append(file.name, file);
    const config = {
        headers: {
            Authorization: getToken(),
            "content-type": file.type,
        },
    };
    return PostRequest(`${API_URL_EXPENSE}/upload_expense/${idUser}&${idExpense}`, formData, config);
};

export {useFetchExpense, AddExpense, UpdateExpense, DeleteExpense, UploadExpense};
