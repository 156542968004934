export const enum TypeStatusDeclaration {
    OPEN = 0,
    DONE = 1,
}

class StatusDeclaration {
    public static ListStatus = [TypeStatusDeclaration.OPEN, TypeStatusDeclaration.DONE];

    public static toString(type: TypeStatusDeclaration): string {
        switch (type) {
            case TypeStatusDeclaration.OPEN:
                return "Ouvert";
            case TypeStatusDeclaration.DONE:
                return "Fermé";
        }
    }
    public static toColor(type: TypeStatusDeclaration): string {
        switch (type) {
            case TypeStatusDeclaration.OPEN:
                return "green";
            case TypeStatusDeclaration.DONE:
                return "orange";
        }
    }
}

export default StatusDeclaration;
