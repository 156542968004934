import React, {useState} from "react";
import "./FCFloatButton.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

export type actionFloatButton = {
    label: string;
    icon: IconDefinition;
    handlerOnClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

interface FCFloatButtonProps {
    items: actionFloatButton [],
    icon: IconDefinition,
    positionRight?: string,
    positionBottom?: string,
    handlerOnClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const FCFloatButton: React.FC<FCFloatButtonProps> = (props: FCFloatButtonProps) => {

    const [open, setOpen] = useState(false);

    const mouseEnter = () => setOpen(true);
    const mouseLeave = () => setOpen(false);

    return (
        <div style={{right: props.positionRight, bottom: props.positionBottom,zIndex:"99999"}}
             className={open ? "fab-container open" : "fab-container"} onMouseEnter={mouseEnter}
             onMouseLeave={mouseLeave} onClick={props.handlerOnClick}>
            <div className="fab-button divFloatButton">
                <FontAwesomeIcon icon={props.icon}/>
            </div>
            {props.items.map((action, index) => (
                <div style={{transitionDelay: `${index * 25}ms`}}
                     className={open ? "divFloatButton fab-action open" : "divFloatButton fab-action"}
                     key={action.label} onClick={action.handlerOnClick}>

                    <FontAwesomeIcon icon={action.icon}/>
                    <span className="tooltip">{action.label}</span>
                </div>
            ))}
        </div>
    );
};

FCFloatButton.defaultProps = {
    positionRight: "3em",
    positionBottom: "3em"
}

export default FCFloatButton;