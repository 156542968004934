import React, {useEffect, useState} from "react";
import "./FCAlertMsg.scss"

export enum TypeAlertMsg {
    ERROR = 0,
    INFO = 1,
    WARNING = 2,
    SUCCESS = 3
}

type AlertMsgProps = {
    textMsg: string;
    title?: string;
    type: TypeAlertMsg;
    activate: boolean;
};

const FCAlertMsg: React.FunctionComponent<AlertMsgProps> = (props: AlertMsgProps) => {
    const [classNameToast, setClassNameToast] = useState("");
    const [stateActivate] = useState(props.activate);

    useEffect(() => {
        let classType: string = "errorAlertMsg";
        switch (props.type) {
            case TypeAlertMsg.SUCCESS:
                classType = "successAlertMsg";
                break;

            case TypeAlertMsg.INFO:
                classType = "infoAlertMsg";
                break;

            case TypeAlertMsg.WARNING:
                classType = "warningAlertMsg";
                break;

            default:
            case TypeAlertMsg.ERROR:
                classType = "errorAlertMsg";
                break;
        }

        if (stateActivate) {
            setClassNameToast("alertMsgShow " + classType);
            setTimeout(function () {
                setClassNameToast(classType);
            }, 9500);
        }
    }, []);

    return (
        <div>
            <div className={"alertMsg " + classNameToast + " errorAlertMsg"}>
                <div className={"alertMsgDivContent"}>
                    {props.title &&
                        <div className={"alertMsgTitle"}>
                            {props.title}
                        </div>
                    }
                    <div className={"alertMsgContent"}>
                        {props.textMsg}
                    </div>

                </div>
            </div>
        </div>
    );
}

export default FCAlertMsg;