import React, {useState} from 'react';
import "./FormAccount.scss"
import FCDropdown, {SelectOption} from "../../../FCObjets/Form/FCDropdown";
import FCInput from "../../../FCObjets/Form/FCInput";

export interface InterfaceAccountUser {
    firstname: string;
    lastname: string;
    contactEmail: string;
    civility: string;
    titleJob: string;
    urlCV: string;
}

export type AccountUserProps = {
    handlerChange: (dataUser: InterfaceAccountUser) => void;
    data: InterfaceAccountUser;
}

const FormAccountUser: React.FC<AccountUserProps> = ({handlerChange, data}) => {

    const [dataUser, setDataUser] = useState<InterfaceAccountUser>(data);

    let optionCivility: SelectOption[] = [];
    optionCivility.push({value: "Monsieur", label: "Monsieur"});
    optionCivility.push({value: "Madame", label: "Madame"});
    optionCivility.push({value: "Non binaire", label: "Non binaire"});

    const eventChange = (key: "firstname" | "lastname" | "civility" | "titleJob" | "urlCV" | "contactEmail", value: string) => {
        let v: InterfaceAccountUser = {
            firstname: dataUser.firstname,
            lastname: dataUser.lastname,
            civility: dataUser.civility,
            titleJob: dataUser.titleJob,
            urlCV: dataUser.urlCV,
            contactEmail: dataUser.contactEmail
        }
        v[key] = value;
        setDataUser(v);
        handlerChange(v);
    }

    return (
        <React.Fragment>
            {(data) && (
                <div>
                    <FCDropdown onChangeHandler={(e) => eventChange("civility", e.target.value)}
                                options={optionCivility}
                                label={"Civilité"} id={"civility"} value={dataUser.civility}
                                widthDropdown={"311px"} colorTextLabel={"#FFFFFF"} widthLabel={"200px"}/>
                    <FCInput onChangeHandler={(e) => eventChange("firstname", e.target.value)} label={"Prénom"}
                             id={"prenom"}
                             value={dataUser.firstname} widthInput={"300px"} colorTextLabel={"#FFFFFF"}
                             widthLabel={"200px"}/>
                    <FCInput onChangeHandler={(e) => eventChange("lastname", e.target.value)} label={"Nom"}
                             id={"nom"}
                             value={dataUser.lastname} widthInput={"300px"} colorTextLabel={"#FFFFFF"}
                             widthLabel={"200px"}/>
                    <FCInput onChangeHandler={(e) => eventChange("contactEmail", e.target.value)} label={"Email"}
                             id={"email"}
                             value={dataUser.contactEmail} widthInput={"300px"} colorTextLabel={"#FFFFFF"}
                             widthLabel={"200px"}/>
                    <FCInput onChangeHandler={(e) => eventChange("titleJob", e.target.value)} label={"Status"}
                             id={"titlejob"}
                             value={dataUser.titleJob} widthInput={"300px"} colorTextLabel={"#FFFFFF"}
                             widthLabel={"200px"}/>
                    <FCInput onChangeHandler={(e) => eventChange("urlCV", e.target.value)} label={"URL CV"}
                             id={"urlCV"}
                             value={dataUser.urlCV} widthInput={"300px"} colorTextLabel={"#FFFFFF"}
                             widthLabel={"200px"}/>
                </div>
            )}
        </React.Fragment>
    );
};

export default FormAccountUser;
