import React, {FC} from "react";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Dashboard from "./views/Dashboard/Dashboard";
import "./App.scss";
import CardUser from "./views/CardUser/CardUser";
import ViewCalendar from "./views/ViewCalendar/ViewCalendar";
import ViewPDF from "./views/ViewPDF";
import Error404 from "./views/PageError/Error404";
import {EventUpdatedSizeProvider} from "./store/EventUpdatedWidth";
import CreateAccount from "./views/CreateAccount/CreateAccount";
import WaitValidate from "./views/WaitValidate/WaitValidate";

const App: FC = () => {
    return (
        <EventUpdatedSizeProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Dashboard/>} />
                    <Route path="/user/:username" element={<CardUser />} />
                    <Route path="/user/:username/planning" element={<ViewCalendar />} />
                    <Route path="/pdf/:type/:id" element={<ViewPDF/>} />
                    <Route path="/pdf/:path" element={<ViewPDF/>} />
                    <Route path="/create_account" element={<CreateAccount/>} />
                    <Route path="/create_account/wait_validate" element={<WaitValidate />} />
                    <Route path="*" element={<Error404/>}/>
                </Routes>
            </BrowserRouter>
        </EventUpdatedSizeProvider>

    );
};

export default App;
