import React from "react";

type InputTableProps = {
    edit: boolean;
    value: string | number;
    type?: string;
    width?: string;
    min?: string;
    max?: string;
    step?: string;
    pattern?:string;
    onChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const InputTable: React.FunctionComponent<InputTableProps> = (props) => {

    return (
        <React.Fragment>
            {
                props.edit ? (
                    <input className={"FCInput"} min={props.min} max={props.max} type={props.type} value={props.value}
                           style={{width: props.width}} step={props.step} pattern={props.pattern}
                           onChange={props.onChangeHandler}
                    />
                ) : props.value
            }
        </React.Fragment>
    );

}

InputTable.defaultProps = {
    type: "text",
    width: "160px",
    step: "1",
    min:"0"
};

export default InputTable;