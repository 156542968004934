import React, {useState} from 'react';
import "./FormAccount.scss"
import FCDropdown, {SelectOption} from "../../../FCObjets/Form/FCDropdown";
import StatusAccountUser from "../../../../utils/StatusAccountUser";

export interface InterfaceAccountAdmin {
    role: string;
    stateAccount: number;
}

export type AccountAdminProps = {
    handlerChange: (dataUser: InterfaceAccountAdmin) => void;
    data: InterfaceAccountAdmin;
}

const FormAccountAdmin: React.FC<AccountAdminProps> = ({handlerChange, data}) => {

    const [dataAdmin, setDataAdmin] = useState<InterfaceAccountAdmin>(data);

    let optionRole: SelectOption[] = [];
    optionRole.push({value: "ADMIN", label: "Admin"});
    optionRole.push({value: "GUEST", label: "Guest"});

    let optionsStateAccount: SelectOption[] = []
    for (let i = 0; i < StatusAccountUser.ListStatus.length; i++) {
        optionsStateAccount.push({
            value: StatusAccountUser.ListStatus[i].toString(),
            label: StatusAccountUser.toString(StatusAccountUser.ListStatus[i])
        });
    }

    const eventChange = (key: "role" | "stateAccount", value: string|number) => {
        let v: any = {
            role: dataAdmin.role,
            stateAccount: dataAdmin.stateAccount
        }
        v[key] = value;
        setDataAdmin(v);
        handlerChange(v);
    }

    return (
        <React.Fragment>
            {(data) && (
                <div>
                    <FCDropdown onChangeHandler={(e) => eventChange("role", e.target.value)}
                                options={optionRole}
                                label={"Role"} id={"role"} value={dataAdmin.role}
                                widthDropdown={"311px"} colorTextLabel={"#FFFFFF"} widthLabel={"200px"}/>
                    <FCDropdown onChangeHandler={(e) => eventChange("stateAccount", parseInt(e.target.value))}
                                options={optionsStateAccount}
                                label={"Etat du compte"} id={"stateAccount"} value={dataAdmin.stateAccount}
                                widthDropdown={"311px"} colorTextLabel={"#FFFFFF"} widthLabel={"200px"}/>
                </div>
            )}
        </React.Fragment>
    );
};

export default FormAccountAdmin;
