import React, {useState} from "react";
import "./ExplorerFiles.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFolderPlus,
    faFolderMinus,
    faFilePdf,
    faFileWord,
    faFileText,
    faFileExcel,
    faFileImage,
    faFile, faFileZipper
} from "@fortawesome/free-solid-svg-icons";
import FCSpinner from "../../FCObjets/FCSpinner";
import FCAlertMsgErrorAPI from "../../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";
import {useFetchExplorer} from "../../../service/Business/ExplorerService";
import {getIdWithToken} from "../../../utils/storage/Token";
import {AppSettings} from "../../../AppSettings";

interface TreeNode {
    title: string;
    key: string;
    type: string;
    children?: TreeNode[];
}

interface DirectoryTreeProps {
}

interface DirectoryTreeState {
    openKeys: string[];
}

const ExplorerFiles: React.FC<DirectoryTreeProps> = () => {

    const [directoryTreeState, setDirectoryTreeState] = useState<DirectoryTreeState>({
        openKeys: [],
    })

    const {data: treeData, loading, error} = useFetchExplorer(getIdWithToken());

    const getIconWithType = (type: string) => {
        if (type === "pdf") {
            return (<FontAwesomeIcon icon={faFilePdf} color={"#E74C3C"}/>)
        } else if (type === "word") {
            return (<FontAwesomeIcon icon={faFileWord} color={"#3498DB"}/>)
        } else if (type === "text") {
            return (<FontAwesomeIcon icon={faFileText} color={"#FFFFFF"}/>)
        } else if (type === "excel") {
            return (<FontAwesomeIcon icon={faFileExcel} color={"#16A085"}/>)
        } else if (type === "zip") {
            return (<FontAwesomeIcon icon={faFileZipper} color={"#F1C40F"}/>)
        } else if (type === "image") {
            return (<FontAwesomeIcon icon={faFileImage} color={"#9B59B6"}/>)
        }
        return (<FontAwesomeIcon icon={faFile}/>)
    }

    const handleFolderClick = (key: string) => {
        const {openKeys} = directoryTreeState;
        if (openKeys.includes(key)) {
            // Le dossier est ouvert, fermez-le
            setDirectoryTreeState({openKeys: openKeys.filter((k) => k !== key)});
        } else {
            // Le dossier est fermé, ouvrez-le
            setDirectoryTreeState({openKeys: [...openKeys, key]});
        }
    };

    const handleClickItem = (item: TreeNode) => {
        if (item.type === "pdf") {
            window.open(`${process.env.PUBLIC_URL}/pdf/${encodeURIComponent(item.key)}`, "_blank")
        } else {
            const url = `${AppSettings.API_ENDPOINT}${AppSettings.API_FREELANCERIE}/explorer/view/${getIdWithToken().toString()}/${encodeURIComponent(item.key)}`;
            window.open(url, "_blank");
        }
    }

    const renderTreeNodes = (data: TreeNode[]) => {
        return data.map((item) => (
            <li key={item.key}>
                {
                    item.children ? (
                        <React.Fragment>
                <span onClick={() => handleFolderClick(item.key)}>
                    {
                        directoryTreeState.openKeys.includes(item.key) ?
                            <FontAwesomeIcon icon={faFolderMinus}/> :
                            <FontAwesomeIcon icon={faFolderPlus}/>
                    }
                    <div className={"divTitle"}>
                        {
                            item.title
                        }
                    </div>
                </span>
                            <ul style={{display: directoryTreeState.openKeys.includes(item.key) ? 'block' : 'none'}}>
                                {
                                    renderTreeNodes(item.children)
                                }
                            </ul>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                <span onClick={() => handleClickItem(item)}>
                    {
                        getIconWithType(item.type)
                    }
                    <div className={"divTitle"}>
                        {
                            item.title
                        }
                    </div>
                </span>
                        </React.Fragment>
                    )
                }
            </li>
        ));
    }

    return (
        <React.Fragment>
            {treeData &&
                <div className="explorer-files-content">
                    <ul>
                        {
                            renderTreeNodes(treeData)
                        }
                    </ul>
                </div>
            }
            {loading && <FCSpinner size={"small"}/>}
            {error && <FCAlertMsgErrorAPI error={error}/>}
        </React.Fragment>
    );

}

export default ExplorerFiles;