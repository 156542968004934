export const enum TypeStatusBilling {
    INITIALIZED = 0,
    CANCELED = 1,
    DELIVERY_ORDER_INIT = 2,
    DELIVERY_ORDER_SENT = 3,
    DELIVERY_ORDER_SIGNED = 4,
    BILL_INIT = 5,
    BILL_SENT = 6,
    BILL_PAID = 7,
}

class StatusBilling {
    public static ListStatus = [
        TypeStatusBilling.INITIALIZED,
        TypeStatusBilling.CANCELED,
        TypeStatusBilling.DELIVERY_ORDER_INIT,
        TypeStatusBilling.DELIVERY_ORDER_SENT,
        TypeStatusBilling.DELIVERY_ORDER_SIGNED,
        TypeStatusBilling.BILL_INIT,
        TypeStatusBilling.BILL_SENT,
        TypeStatusBilling.BILL_PAID,
    ];

    public static toString(type: TypeStatusBilling): string {
        switch (type) {
            case TypeStatusBilling.INITIALIZED:
                return "Initialisé";
            case TypeStatusBilling.CANCELED:
                return "Annulé";
            case TypeStatusBilling.DELIVERY_ORDER_INIT:
                return "BL. Initialisé";
            case TypeStatusBilling.DELIVERY_ORDER_SENT:
                return "BL. Envoyé";
            case TypeStatusBilling.DELIVERY_ORDER_SIGNED:
                return "BL. Signé";
            case TypeStatusBilling.BILL_INIT:
                return "Fact. Initialisée";
            case TypeStatusBilling.BILL_SENT:
                return "Fact. envoyée";
            case TypeStatusBilling.BILL_PAID:
                return "Fact. payée";
        }
        return "Inconnu";
    }
    public static toColor(type: TypeStatusBilling): string {
        switch (type) {
            case TypeStatusBilling.INITIALIZED:
                return "yellow";
            case TypeStatusBilling.CANCELED:
                return "red";
            case TypeStatusBilling.DELIVERY_ORDER_INIT:
                return "orange";
            case TypeStatusBilling.DELIVERY_ORDER_SENT:
                return "purple";
            case TypeStatusBilling.DELIVERY_ORDER_SIGNED:
                return "blue";
            case TypeStatusBilling.BILL_INIT:
                return "teal";
            case TypeStatusBilling.BILL_SENT:
                return "green";
            case TypeStatusBilling.BILL_PAID:
                return "white";
        }
        return "white";
    }
}

export default StatusBilling;
