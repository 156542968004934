import React from "react";
import "./FCAlertMsg.scss"
import FCAlertMsg, {TypeAlertMsg} from "./FCAlertMsg";

type AlertMsgErrorAPIProps = {
    error: any;
    activate?:boolean;
};

const FCAlertMsgErrorAPI: React.FunctionComponent<AlertMsgErrorAPIProps> = (props: AlertMsgErrorAPIProps) => {
    return (
        <FCAlertMsg textMsg={"URL : [" + props.error.config.url + "] => " + props.error.message} type={TypeAlertMsg.ERROR}
                    activate={props.activate ? props.activate : true} title={"Erreur API"}/>
    );
}

FCAlertMsgErrorAPI.defaultProps = {
    activate:true
}

export default FCAlertMsgErrorAPI;