import React, {useState} from "react";

import {CustomerInterface} from "../../../service/Business/Interfaces/CustomerInterface";
import FCInput from "../../FCObjets/Form/FCInput";
import FCButton from "../../FCObjets/FCButton";
import {AddCustomer, UpdateCustomer} from "../../../service/Business/CustomerService";
import {getIdWithToken} from "../../../utils/storage/Token";
import FCAutoCompleteInput from "../../FCObjets/Form/FCAutoCompleteInput";
import {CallbackErrorModalFL, CallbackFinishedModalFL} from "../../Dashboard/Form/ModalForm";
import {ContactInterface} from "../../../service/Business/Interfaces/ContactInterface";
import {
    AddContact,
    DeleteContact, UpdateContact,
    useFetchContacts
} from "../../../service/Business/ContactService";
import FCSpinner from "../../FCObjets/FCSpinner";
import FCAlertMsgErrorAPI from "../../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";
import TableContactCustomer from "./Tables/TableContactCustomer";

type FormCustomerListProps = {
    onFinished: CallbackFinishedModalFL;
    onError: CallbackErrorModalFL,
    dataCustomer: CustomerInterface | undefined;
};

const FormCustomer: React.FunctionComponent<FormCustomerListProps> = ({dataCustomer, onFinished, onError}) => {

    const {data: contacts, loading, error} = useFetchContacts(dataCustomer ? dataCustomer.id : 0);

    const [inputName, setInputName] = useState(dataCustomer ? dataCustomer.companyName : "");
    const [inputSiret, setSiret] = useState(dataCustomer ? dataCustomer.siret : "");
    const [inputAddress, setAddress] = useState(dataCustomer ? dataCustomer.address : "");
    const [inputLat, setLat] = useState(dataCustomer ? dataCustomer.lat : 0);
    const [inputLng, setLng] = useState(dataCustomer ? dataCustomer.lng : 0);
    const [listContactAdd, setListContactAdd] = useState<ContactInterface[]>([]);
    const [listContactDel, setListContactDel] = useState<ContactInterface[]>([]);
    const [listContactModify, setListContactModify] = useState<ContactInterface[]>([]);

    const addressChanged = async (fullAddress: string) => {
        setAddress(fullAddress)
        const url = `https://api-adresse.data.gouv.fr/search/?q=${fullAddress}`;
        const headers = new Headers();
        headers.append("Accept", "application/json");
        try {
            const response = await fetch(url, {headers});
            const data = await response.json();
            let {features} = data;
            let res = features.map((e: { geometry: { coordinates: any; }; }) => {
                return {lng: e.geometry.coordinates[0], lat: e.geometry.coordinates[1]}
            });
            if (res.length >= 1) {
                let {lat, lng} = res[0];
                setLat(lat);
                setLng(lng);
            }
        } catch (error) {
            console.error(error);
        }
    }
    /**
     * Validation du formulaire
     */
    const handleSubmit = () => {
        onError(null);

        let customer: CustomerInterface = {
            companyName: inputName,
            siret: inputSiret,
            address: inputAddress,
            id: dataCustomer ? dataCustomer.id : -1,
            userCnx: undefined,
            lat: inputLat,
            lng: inputLng
        };

        // Mise à jour des contacts clients
        if (listContactAdd.length !== 0) {
            AddContact(getIdWithToken(), {
                id_customer: dataCustomer ? dataCustomer.id : 0,
                listContacts: listContactAdd
            })
                .then(() => console.log('Ajout liste contact OK'))
                .catch((e) => console.error(e.message));
        }
        if (listContactDel.length !== 0) {
            for (let i = 0; i < listContactDel.length; i++) {
                DeleteContact(getIdWithToken(), listContactDel[i])
                    .then(() => console.log('delete contact OK'))
                    .catch((e) => console.error(e.message));
            }
        }
        if (listContactModify.length !== 0) {
            UpdateContact(getIdWithToken(), {listContacts : listContactModify})
                .then(() => console.log('Modify contact OK'))
                .catch((e) => console.error(e.message));
        }

        // Mise à jour des clients
        if (dataCustomer) {
            UpdateCustomer(getIdWithToken(), customer).then(() => {
                onFinished();
            }).catch((err) => onError(err));
        } else {
            AddCustomer(getIdWithToken(), customer).then(() => {
                onFinished();
            }).catch((err) => onError(err));
        }
    }

    /**
     * Gestion de l'evenement de contact
     * @param listAdd
     * @param listDel
     * @param listModify
     */
    const handlerEventContact = (listAdd: ContactInterface[], listDel: ContactInterface[], listModify: ContactInterface[]) => {
        setListContactAdd(listAdd);
        setListContactDel(listDel);
        setListContactModify(listModify);
    }

    return (
        <form>
                <FCInput onChangeHandler={(e) => setInputName(e.target.value)} label={"Nom :"} id={"nom"}
                         value={inputName} widthInput={"350px"} widthLabel={"60px"}/>
                <FCInput onChangeHandler={(e) => setSiret(e.target.value)} label={"Siret :"} id={"siret"}
                         value={inputSiret} widthInput={"350px"} widthLabel={"60px"}/>
                <FCAutoCompleteInput onChangeHandler={(e) => addressChanged(e.target.value)} label={"Adresse :"}
                                     id={"adresse"} value={inputAddress} widthInput={"350px"} widthLabel={"350px"}/>

                {(contacts && dataCustomer) &&
                    <TableContactCustomer dataContact={contacts} handlerEvent={handlerEventContact}/>
                }
                {loading && <FCSpinner size={"small"}/>}
                {error && <FCAlertMsgErrorAPI error={error}/>}
                 <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between",alignSelf:"flex-end",alignItems:"baseline"}}>
                    <FCButton label={"Valider"} type={"button"} onClickHandler={handleSubmit} style={{width: "60px"}}/>

                 </div>
        </form>
    );
}

export default FormCustomer;