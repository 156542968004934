import React, {useEffect, useState} from "react";
import "./ContentDeclarationURSSAF.scss";
import StatusBilling from "../../../../utils/Business/StatusBilling";
import FCTag from "../../../FCObjets/FCTag";
import FCDropdown, {SelectOption} from "../../../FCObjets/Form/FCDropdown";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import FCIconButton from "../../../FCObjets/FCIconButton";
import {
    BillingDeclarationUrssafInterface,
    BillingChangeDeclarationURSSAFPostInterface,
    EcheanceURSSAFInterface,
    DeclarationInterface,
} from "../../../../service/Business/Interfaces/DeclarationInterface";
import {getIdWithToken} from "../../../../utils/storage/Token";
import {UpdateDeclaration} from "../../../../service/Business/DeclarationService";
import {TypeStatusDeclaration} from "../../../../utils/Business/StatusDeclaration";

type LineDeclarationUrssafProps = {
    year: number;
    billing: BillingDeclarationUrssafInterface;
    echeancesOpen: EcheanceURSSAFInterface[];
    decBDD: DeclarationInterface;
    onUpdated: () => void;
};

const LineDeclarationURSSAF: React.FunctionComponent<LineDeclarationUrssafProps> = (props) => {

    const [optionsEcheanceUrssaf, setOptionsEcheanceUrssaf] = useState(props.decBDD.num_declaration.toString());
    const [options, setOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        if (props.echeancesOpen) {
            const options = props.echeancesOpen.map((e): SelectOption => {
                return {value: e.value, label: e.label};
            })
            setOptions(options);
        }
    }, [props.echeancesOpen]);

    const submit = () => {
        const declaration: BillingChangeDeclarationURSSAFPostInterface = {
            idBilling: props.billing.id, num_declaration: parseInt(optionsEcheanceUrssaf), year: props.year
        }
        UpdateDeclaration(getIdWithToken(), declaration)
            .then(() => {
                props.onUpdated();
            })
            .catch((e) => {
                console.error(e)
            })
    }

    return (
        <React.Fragment>
            <td data-label="Numéro">{props.billing.num}</td>
            <td data-label="Statut"><FCTag value={StatusBilling.toString(props.billing.state)}
                                           color={StatusBilling.toColor(props.billing.state)}
                                           style={{fontWeight: "bold"}}/></td>
            <td data-label="Tot. HT">{props.billing.tot} &euro;</td>
            <td data-label="Début">{new Date(props.billing.begin).toLocaleDateString()}</td>
            <td data-label="Fin">{new Date(props.billing.end).toLocaleDateString()}</td>
            <td data-label="Actions">
                <div className="row-content">
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyItems: "left",
                        width: "100%"
                    }}>
                        { props.decBDD.state === TypeStatusDeclaration.OPEN ?
                            <React.Fragment>
                                <FCDropdown
                                    onChangeHandler={(e) => setOptionsEcheanceUrssaf(e.target.value)}
                                    options={options} label={""} id={"contrat"} value={optionsEcheanceUrssaf}/>

                                <FCIconButton style={{height: ""}} icon={faCheck} tooltip={"Valider"} color={"green"}
                                              onClickHandler={submit}/>
                            </React.Fragment> : <>{props.decBDD.desc}</>
                        }
                    </div>
                </div>
            </td>
        </React.Fragment>
    );
}

export default LineDeclarationURSSAF;