import React from "react";
import 'leaflet/dist/leaflet.css';
import FCMap from "../../FCObjets/FCMap";
import {useFetchAddressBook} from "../../../service/Business/MapService";
import FCAlertMsgErrorAPI from "../../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";


const ContentAddress: React.FunctionComponent = () => {

    const {data: addressBook, loading, error} = useFetchAddressBook();

    return (
        <React.Fragment>
            <FCMap listMarkerAddressBook={(!loading && !error) ? addressBook : []} zoomMap={12}/>
            {
                error && <FCAlertMsgErrorAPI error={error}/>
            }
        </React.Fragment>
    );
}


export default ContentAddress;