import React, {ChangeEvent, useState} from 'react';
import FCInput from "../../../components/FCObjets/Form/FCInput";
import "./CreateAccountEnterprise.scss";
import FCCheckbox from "../../../components/FCObjets/Form/FCCheckbox";

export interface InterfaceCreateAccountEnterprise {
    companyName: string;
    companySiret: string;
    companyLocation: string;
    declarationLocation: string;
    tjm: number;
    logo: string;
    vl: boolean;
}

export type CreateAccountEnterpriseProps = {
    handlerChange: (dataEnterprise: InterfaceCreateAccountEnterprise) => void;
    verifyInput: boolean
}

const CreateAccountEnterprise: React.FC<CreateAccountEnterpriseProps> = (props: CreateAccountEnterpriseProps) => {

    const [dataEnterprise, setDataEnterprise] = useState<InterfaceCreateAccountEnterprise>({
        companyLocation: "",
        companyName: "",
        companySiret: "",
        declarationLocation: "",
        tjm: 400,
        logo: "",
        vl: true,
    });

    const eventChange = (key: "companyName"|"companySiret"|"companyLocation"|"declarationLocation"|"tjm"|"logo"|"vl", value:string|number|boolean) => {
        let v:any = {
            companyName: dataEnterprise.companyName,
            companySiret: dataEnterprise.companySiret,
            companyLocation: dataEnterprise.companyLocation,
            declarationLocation: dataEnterprise.declarationLocation,
            tjm: dataEnterprise.tjm,
            logo: dataEnterprise.logo,
            vl: dataEnterprise.vl
        }
        v[key] = value;
        setDataEnterprise(v);
        props.handlerChange(v);
    }

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file:File = e.target.files[0];
            file.text().then((result) => eventChange("logo", result));
        }
    };

    return (
        <div className="createaccountenterpriseview">
            <div className={"title-createaccountenterpriseview"}>Données de l'entreprise</div>
            <div className={"form-createaccountenterpriseview"}>
                <FCInput onChangeHandler={(e) => eventChange("companyName", e.target.value)}
                         label={"Nom de l'entreprise *"} id={"companyName"} value={dataEnterprise.companyName}
                         widthInput={"300px"} colorTextLabel={"#FFFFFF"} widthLabel={"auto"}
                         backgroundColor={props.verifyInput && dataEnterprise.companyName == "" ? "#E74C3C" : undefined}/>

                <FCInput onChangeHandler={(e) => eventChange("companySiret", e.target.value)}
                         label={"N° Siret de l'entreprise *"} id={"companySiret"} value={dataEnterprise.companySiret}
                         widthInput={"300px"} colorTextLabel={"#FFFFFF"} widthLabel={"auto"}
                         backgroundColor={props.verifyInput && dataEnterprise.companySiret == "" ? "#E74C3C" : undefined}/>

                <FCInput onChangeHandler={(e) => eventChange("companyLocation", e.target.value)}
                         label={"Adresse de l'entreprise *"} id={"companyLocation"} value={dataEnterprise.companyLocation}
                         widthInput={"300px"} colorTextLabel={"#FFFFFF"} widthLabel={"auto"}
                         backgroundColor={props.verifyInput && dataEnterprise.companyLocation == "" ? "#E74C3C" : undefined}/>

                <FCInput onChangeHandler={(e) => eventChange("declarationLocation", e.target.value)}
                         label={"Ville pour les Déclarations *"} id={"declarationLocation"} value={dataEnterprise.declarationLocation}
                         widthInput={"300px"} colorTextLabel={"#FFFFFF"} widthLabel={"auto"}
                         backgroundColor={props.verifyInput && dataEnterprise.declarationLocation == "" ? "#E74C3C" : undefined}/>

                <FCInput onChangeHandler={(e) => eventChange("tjm", e.target.value)}
                         label={"TJM défaut *"} id={"tjm"} value={dataEnterprise.tjm} widthInput={"300px"} type={"number"}
                         colorTextLabel={"#FFFFFF"} min={0} max={1000} widthLabel={"auto"}
                         backgroundColor={props.verifyInput && dataEnterprise.tjm == 0 ? "#E74C3C" : undefined}/>

                <FCCheckbox onChangeHandler={(e) => eventChange("vl", e.target.checked)}
                            label={"Versement libératoire"} id={"vl"}
                            value={dataEnterprise.vl} widthLabel={"200px"}/>

                <FCInput onChangeHandler={handleFileChange}
                         label={"Logo de l'entreprise"} id={"fileInput"} widthInput={"300px"}
                         colorTextLabel={"#FFFFFF"}
                         widthLabel={"200px"} type={"file"} value={undefined} accept=".svg" colorInput={"#FFFFFF"}/>
                {dataEnterprise.logo &&
                    <img src={'data:image/svg+xml;base64,'+Buffer.from(dataEnterprise.logo).toString("base64")} alt={"svg"} height={70}/>}
            </div>
        </div>
    );
};

export default CreateAccountEnterprise;