import { useGetRequest, RequestResponse, RequestPromise, PutRequest } from "../../hook/useRequest";
import { getToken } from "../../utils/storage/Token";
import { AppSettings } from "../../AppSettings";
import {
    BillingChangeDeclarationURSSAFPostInterface,
    DeclarationURSSAFChangeStateInterface,
} from "./Interfaces/DeclarationInterface";

const API_URL_DECLARATION = AppSettings.API_FREELANCERIE + "/declaration";

const useFetchDeclarationUrssaf = (idUser: number, year: number, forceUpdated = 0): RequestResponse => {
    const config = { headers: { Authorization: getToken() } };
    return useGetRequest(
        `${AppSettings.API_FREELANCERIE}/declaration/resume_urssaf/${idUser}&${year}`,
        forceUpdated,
        config,
    );
};

const useFetchDeclarationResume = (idUser: number, year: number, forceUpdated = 0): RequestResponse => {
    const config = { headers: { Authorization: getToken() } };
    return useGetRequest(`${AppSettings.API_FREELANCERIE}/declaration/resume/${idUser}&${year}`, forceUpdated, config);
};

const useFetchEcheanceUrssaf = (idUser: number, year: number, forceUpdated = 0): RequestResponse => {
    const config = { headers: { Authorization: getToken() } };
    return useGetRequest(
        `${AppSettings.API_FREELANCERIE}/declaration/echeance_urssaf/${idUser}&${year}`,
        forceUpdated,
        config,
    );
};

const UpdateChangeStatusDeclarationURSSAF = (
    id_user: number,
    declaration: DeclarationURSSAFChangeStateInterface,
): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return PutRequest(`${API_URL_DECLARATION}/state_urssaf/${id_user.toString()}`, declaration, config);
};

const UpdateDeclaration = (
    id_user: number,
    declaration: BillingChangeDeclarationURSSAFPostInterface,
): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return PutRequest(`${API_URL_DECLARATION}/urssaf/${id_user.toString()}`, declaration, config);
};

export {
    useFetchDeclarationUrssaf,
    UpdateDeclaration,
    useFetchDeclarationResume,
    useFetchEcheanceUrssaf,
    UpdateChangeStatusDeclarationURSSAF,
};
