import { getToken } from "../utils/storage/Token";
import { useGetRequest, RequestResponse, PutRequest, RequestPromise, GetRequest } from "../hook/useRequest";

const useFetchConfigUser = (userdata_id: number): RequestResponse => {
    const config = { headers: { Authorization: getToken() } };
    return useGetRequest("/account/config_user/" + userdata_id.toString(), 0, config);
};

const UpdateUserInfo = (id_user: number, userInfo: unknown): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return PutRequest(`/account/config_user/${id_user}`, userInfo, config);
};

const GetUsernameFree = (username: string): RequestPromise => {
    return GetRequest(`/account/username_free/${username}`);
};

export { useFetchConfigUser, UpdateUserInfo, GetUsernameFree };
