import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import "./FCIconButton.scss"
import FCTooltip from "./FCTooltip";

type IconButtonProps = {
    onClickHandler?: (event: React.MouseEvent<HTMLDivElement>) => void;
    tooltip: string;
    icon:IconDefinition;
    color?:string;
    style?: any;
};

const FCIconButton: React.FunctionComponent<IconButtonProps> = (props: IconButtonProps) => {
    function hex2rgb(hex: string, opacity: number) {
        let e: string = hex.replace('#', '');
        let h = e.match(new RegExp('(.{' + e.length / 3 + '})', 'g'));

        if (h === null) {
            return hex;
        }
        let r: number[] = [];
        for (var i = 0; i < h.length; i++)
            r[i] = parseInt(h[i].length === 1 ? h[i] + h[i] : h[i], 16);

        if (typeof opacity !== 'undefined') r.push(opacity);

        return 'rgba(' + r.join(',') + ')';
    }

    let color: string = "#FFFFFF";
    //CUSTOMS Colors:
    if (props.color === "blue")           color = "#3498DB";
    else if (props.color === "red")       color = "#E74C3C";
    else if (props.color === "light-red") color = "#FF0000";
    else if (props.color === "grey")      color = "#333333";
    else if (props.color === "green")     color = "#2ECC71";
    else if (props.color === "yellow")    color = "#F1C40F";
    else if (props.color === "purple")    color = "#9B59B6";
    else if (props.color === "geekblue")  color = "#95A5A6";
    else if (props.color === "blue")      color = "#023bf4";
    else if (props.color === "teal")      color = "#02f4d0";
    else if (props.color === "purple")    color = "#d90098";
    else if (props.color === "orange")    color = "#F39C12";
    else if (props.color === "white")     color = "#FFFFFF";
    else if (props.color === "black")     color = "#000000";

    return (
        <div className={"iconButton tooltip"} onClick={props.onClickHandler} style={{
                   ...{
                       background: hex2rgb(color, 0.1),
                       color: hex2rgb(color, 1),
                       border: "1px solid " + hex2rgb(color, 0.5)
                   }, ...props.style
               }}>
            <FontAwesomeIcon icon={props.icon}  />
            {
                props.tooltip && <FCTooltip><div>{props.tooltip}</div></FCTooltip>
            }
        </div>
    );
}

export default FCIconButton;