import { useGetRequest, RequestResponse } from "../../hook/useRequest";
import { AppSettings } from "../../AppSettings";
import { getToken } from "../../utils/storage/Token";

const API_URL_MAP = AppSettings.API_FREELANCERIE + "/map";

const useFetchAddressBook = (forceUpdated = 0): RequestResponse => {
    const config = { headers: { Authorization: getToken() } };
    return useGetRequest(`${API_URL_MAP}/address_book`, forceUpdated, config);
};

export { useFetchAddressBook };
