import React from "react";
import "./FCButton.scss"

type ButtonProps = {
    onClickHandler?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    label: string;
    style?: any;
    type?: "submit" | "button" | "reset";
};

const FCButton: React.FunctionComponent<ButtonProps> = (props: ButtonProps) => {

    return (
        <button style={props.style} type={props.type} className={"btn"}
                onClick={props.onClickHandler}> {props.label} </button>
    );
}

FCButton.defaultProps = {
    type: "submit",
}

export default FCButton;