import React, {ReactNode, useState} from "react";
import FCAlertMsg, {TypeAlertMsg} from "../FCAlertMsg/FCAlertMsg";
import ModalForm from "../../Dashboard/Form/ModalForm";
import {TypeForm} from "../../../types/EnumTypeForm";

export type CallbackFinishedModalFL = () => void;

type ContentModalFLForm = {
    children: ReactNode;
    form: DataForm;
    onModalFormFinished: CallbackFinishedModalFL;
}

export type DataForm = {
    type: TypeForm,
    data: any | undefined,
}

const FCContentModalFL: React.FunctionComponent<ContentModalFLForm> = ({children, form, onModalFormFinished}) => {

    const [validate, setValidate] = useState<string|undefined>(undefined);

    return (
        <div>
            {children}
            <ModalForm typeForm={form.type}
                       onModalFormFinished={(textValidate) => {
                                       setValidate(textValidate);
                                       onModalFormFinished();
                                   }}
                       data={form.data}/>

            {
                validate && <FCAlertMsg textMsg={validate}
                                        type={TypeAlertMsg.SUCCESS} activate={validate !== ""}/>
            }
        </div>
    );
}
export default FCContentModalFL;