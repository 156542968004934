import {
    useGetRequest,
    RequestResponse,
    PutRequest,
    PostRequest,
    RequestPromise,
    DeleteRequest,
} from "../../hook/useRequest";
import { AppSettings } from "../../AppSettings";
import { BusinessDealContentInterface, BusinessDealInterface } from "./Interfaces/BusinessDealInterface";
import { getToken } from "../../utils/storage/Token";

const API_URL_BUSINESS = AppSettings.API_FREELANCERIE + "/business";

const useFetchBusinessDealContent = (id_user: number, forceUpdated = 0): RequestResponse => {
    const config = { headers: { Authorization: getToken() } };
    return useGetRequest(`${API_URL_BUSINESS}/${id_user.toString()}`, forceUpdated, config);
};

const useFetchOpenBusinessDealContent = (id_user: number, forceUpdated = 0): RequestResponse => {
    const config = { headers: { Authorization: getToken() } };
    return useGetRequest(`${API_URL_BUSINESS}/open/${id_user.toString()}`, forceUpdated, config);
};

const UpdateBusiness = (id_business: number, business: BusinessDealContentInterface): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return PutRequest(`${API_URL_BUSINESS}/${id_business}`, business, config);
};

const AddBusiness = (business: BusinessDealContentInterface): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return PostRequest(API_URL_BUSINESS, business, config);
};

const UploadBusiness = (id_user: number, id_business: number, file: File): RequestPromise => {
    const formData = new FormData();
    formData.append(file.name, file);
    const config = {
        headers: {
            Authorization: getToken(),
            "content-type": file.type,
        },
    };
    return PostRequest(`${API_URL_BUSINESS}/upload/${id_user}&${id_business}`, formData, config);
};

const DeleteBusiness = (business: BusinessDealInterface): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return DeleteRequest(`${API_URL_BUSINESS}/${business.id.toString()}`, config);
};

export {
    useFetchBusinessDealContent,
    useFetchOpenBusinessDealContent,
    UpdateBusiness,
    AddBusiness,
    DeleteBusiness,
    UploadBusiness,
};
