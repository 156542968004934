import { useGetRequest, RequestResponse } from "../../hook/useRequest";
import { AppSettings } from "../../AppSettings";
import { getToken } from "../../utils/storage/Token";

const API_URL_EXPLORER = AppSettings.API_FREELANCERIE + "/explorer";

const useFetchExplorer = (id_user: number, forceUpdated = 0): RequestResponse => {
    const config = { headers: { Authorization: getToken() } };
    return useGetRequest(`${API_URL_EXPLORER}/${id_user.toString()}`, forceUpdated, config);
};

export { useFetchExplorer };
