import React, {useEffect, useState} from "react";
import "./TableForm.scss";
import {faCheck, faPen, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import FCIconButton from "../../../FCObjets/FCIconButton";
import InputTable from "./InputTable";
import {BillingInterface, BillingItemInterface} from "../../../../service/Business/Interfaces/BillingInterface";

type TableBillingItemProps = {
    dataItemBilling: BillingItemInterface[];
    dataBilling:BillingInterface;
    handlerEvent: (listBillingItemAdd: BillingItemInterface[],
                   listBillingItemDel: BillingItemInterface[],
                   listBillingItemModify: BillingItemInterface[]) => void;
};

const TableBillingItem: React.FunctionComponent<TableBillingItemProps> = (props) => {

    const [listBillingItemTable, setListBillingItemTable] = useState<BillingItemInterface[]>(props.dataItemBilling);
    const [dataBilling] = useState<BillingInterface>(props.dataBilling);
    const [listBillingItemAdd, setListBillingItemAdd] = useState<BillingItemInterface[]>([]);
    const [listBillingItemDel, setListBillingItemDel] = useState<BillingItemInterface[]>([]);
    const [listBillingItemModify, setListBillingItemModify] = useState<BillingItemInterface[]>([]);
    //Row Item Form
    const [currentLabel, setCurrentLabel] = useState<string>("");
    const [currentVAT, setCurrentVAT] = useState<string>("");
    const [currentQuantity, setCurrentQuantity] = useState<string>("");
    const [currentUnitPrice, setCurrentUnitPrice] = useState<string>("");

    const [editingRowId, setEditingRowId] = useState<number | null>(null);
    const [cptAdd, setCptAdd] = useState<number>(-1);

    useEffect(() => {
        let listTmp: BillingItemInterface [] = [...props.dataItemBilling, ...listBillingItemAdd]

        // Suppression de l'affichage des contacts supprimés (en BDD)
        for (let i = 0; i < listBillingItemDel.length; i++) {
            listTmp = listTmp.filter((row) => (row.id !== listBillingItemDel[i].id));
        }

        for (let i = 0; i < listBillingItemModify.length; i++) {
            listTmp = listTmp.map((row) => {
                if (row.id === listBillingItemModify[i].id) {
                    return {
                        ...row,
                        label: listBillingItemModify[i].label,
                        VAT: listBillingItemModify[i].VAT,
                        quantity: listBillingItemModify[i].quantity,
                        totalAmountExclTax: listBillingItemModify[i].totalAmountExclTax,
                        totalAmountInclTax: listBillingItemModify[i].totalAmountInclTax,
                        unitPrice: listBillingItemModify[i].unitPrice
                    }
                }
                return row;
            })
        }
        setListBillingItemTable(listTmp);
        props.handlerEvent(listBillingItemAdd, listBillingItemDel, listBillingItemModify);

    }, [listBillingItemAdd, listBillingItemDel, listBillingItemModify]);

    /**
     * Ajout d'un item de facturation dans le tableau
     */
    const addBillingItem = () => {
        const newBilling: BillingItemInterface = {
            VAT: 0.20, id: cptAdd, label: "Prestation Développement", quantity: 0, totalAmountExclTax: 0,
            totalAmountInclTax: 0, unitPrice: dataBilling.businessDeal.details.tjm
        }
        setListBillingItemAdd((listBillingItemAdd) => [...listBillingItemAdd, newBilling]);
        setCptAdd((cptAdd) => cptAdd - 1);
    }

    /**
     * Suppression d'un item de facturation dans le tableau
     */
    const delBillingItem = (billingItem: BillingItemInterface) => {
        if (billingItem.id <= -1) {
            // Contact fraichement ajouté, on le supprime de la liste des ajouts
            setListBillingItemAdd((listBillingItemAdd) => listBillingItemAdd.filter((row) => (row.id !== billingItem.id)));
        } else {
            // On l'ajoute dans une liste
            setListBillingItemDel((listBillingItemDel) => [...listBillingItemDel, billingItem]);
        }
    }

    /**
     * Mise à jour de la valeur
     */
    const handleSaveRow = (id: number, vat: number, label: string, quantity: number, unitPrice: number) => {
        let existOnModify = listBillingItemModify.filter((bilItem => bilItem.id === id));
        let existOnAdd = listBillingItemAdd.filter((bilItem => bilItem.id === id));

        const totalAmountExclTax = quantity * unitPrice;
        const totalAmountInclTax = (quantity * unitPrice * vat) + quantity * unitPrice;

        if (existOnAdd.length > 0) {
            let r = listBillingItemAdd.map((row) => {
                if (row.id === id) {
                    let newRow: BillingItemInterface = {
                        ...row, VAT: vat, label: label, quantity: quantity, totalAmountExclTax: totalAmountExclTax,
                        totalAmountInclTax: totalAmountInclTax, unitPrice: unitPrice
                    };
                    return newRow;
                }
                return row;
            });

            setListBillingItemAdd(r);
        } else if (existOnModify.length > 0) {
            let r = listBillingItemModify.map((row) => {
                if (row.id === id) {
                    let newRow: BillingItemInterface = {
                        ...row, VAT: vat, label: label, quantity: quantity, totalAmountExclTax: totalAmountExclTax,
                        totalAmountInclTax: totalAmountInclTax, unitPrice: unitPrice
                    };
                    return newRow;
                }
                return row;
            });

            setListBillingItemModify(r);
        } else {
            // On vérifie qu'il ne fait pas partie des données ajoutée.

            listBillingItemTable.map((row) => {
                if (row.id === id) {
                    let newRow: BillingItemInterface = {
                        ...row, VAT: vat, label: label, quantity: quantity, totalAmountExclTax: totalAmountExclTax,
                        totalAmountInclTax: totalAmountInclTax, unitPrice: unitPrice
                    };
                    setListBillingItemModify((listBillingItemModify) => [...listBillingItemModify, newRow]);

                    return newRow;
                }
                return row;
            });
        }
    };

    return (
        <React.Fragment>
        <FCIconButton icon={faPlus} tooltip={"Ajouter Element"}  color="green" onClickHandler={addBillingItem} style={{alignSelf:"flex-end"}}/>
        <div style={{marginBottom: 0}}>
            <table className={"tableForm table"} style={{marginBottom: 0, tableLayout: "auto"}}>
                <thead>
                <tr>
                    <th>Description</th>
                    <th>TVA</th>
                    <th>Quantité</th>
                    <th>Prix unitaire</th>
                    <th>Total HT</th>
                    <th>Total TTC</th>
                    <th>
                        <div className="row-content">
                            Actions &nbsp;

                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    listBillingItemTable.map((e: BillingItemInterface, index: React.Key) => {
                        return (
                            <tr key={index}>
                                <td><InputTable edit={editingRowId === e.id} value={editingRowId === e.id ? currentLabel : e.label}
                                                onChangeHandler={(event) =>{
                                                    const value = event.target.value
                                                    setCurrentLabel(value);
                                                    handleSaveRow(e.id, e.VAT, value, e.quantity, e.unitPrice)}}/>
                                </td>
                                <td><InputTable edit={editingRowId === e.id} value={editingRowId === e.id ? currentVAT : e.VAT} type={"text"} width={"100px"}
                                                min={"0"} max={"1"} step={"0.01"} pattern="[0-1]{1}([,\.][0-9]{0,2})?"
                                                onChangeHandler={(event) =>{
                                                    const value = event.target.value.replaceAll(',',".");
                                                    const numberVal = parseFloat(value);
                                                    if(!isNaN(numberVal) && numberVal>=0 && numberVal<=1){
                                                        setCurrentVAT(value);
                                                        handleSaveRow(e.id, numberVal, e.label, e.quantity, e.unitPrice)
                                                    }else{
                                                        setCurrentVAT(value);
                                                        //set error state here
                                                    }
                                                }}/>
                                </td>
                                <td><InputTable edit={editingRowId === e.id} value={editingRowId === e.id ? currentQuantity : e.quantity} type={"text"}
                                                width={"100px"} step={"any"}  pattern="[0-9]+([,\.][0-9]*)?"
                                                onChangeHandler={(event) =>{
                                                    const value = event.target.value.replaceAll(',',".");
                                                    const numberVal = parseFloat(value);
                                                    if(!isNaN(numberVal)){
                                                        setCurrentQuantity(value);
                                                        handleSaveRow(e.id, e.VAT, e.label, numberVal, e.unitPrice)
                                                    }
                                                    else {
                                                        setCurrentQuantity(value);
                                                        //set error state here
                                                    }
                                                }}/>
                                </td>
                                <td><InputTable edit={editingRowId === e.id} value={editingRowId === e.id ? currentUnitPrice : e.unitPrice} type={"text"}
                                                width={"100px"} step={"any"} pattern="[0-9]+([,\.][0-9]*)?"
                                                onChangeHandler={(event) =>{
                                                    const value = event.target.value.replaceAll(',',".");
                                                    const numberVal = parseFloat(value);
                                                    if(!isNaN(numberVal)){
                                                        setCurrentUnitPrice(value);
                                                        handleSaveRow(e.id, e.VAT, e.label, e.quantity, numberVal)
                                                    }
                                                    else {
                                                        setCurrentUnitPrice(value);
                                                        //set error state here
                                                    }
                                                }}/>
                                    &nbsp;&euro;
                                </td>
                                <td>
                                    {editingRowId === e.id ?
                                        <div style={{width: "100px"}}> {e.totalAmountExclTax} &euro;</div> : e.totalAmountExclTax +" €"
                                    }
                                </td>
                                <td>
                                    {editingRowId === e.id ?
                                        <div style={{width: "100px"}}> {e.totalAmountInclTax} &euro;</div> : e.totalAmountInclTax+ " €"
                                    }
                                </td>
                                <td>
                                    {
                                        editingRowId === e.id ?
                                            (<FCIconButton icon={faCheck}  color="green" tooltip={"Valider"}
                                                        onClickHandler={() =>
                                                            setEditingRowId(null)}/>
                                            ) : (
                                                <FCIconButton icon={faPen}  color="green" tooltip={"Modifier"}
                                                            onClickHandler={() => {
                                                                setEditingRowId(e.id)
                                                                setCurrentLabel(e.label)
                                                                setCurrentVAT(e.VAT.toString().replaceAll(",",'.'))
                                                                setCurrentQuantity(e.quantity.toString().replaceAll(",",'.'))
                                                                setCurrentUnitPrice(e.unitPrice.toString().replaceAll(",",'.'))
                                                            }}/>)
                                    }
                                    <FCIconButton icon={faTrash}  color="red" tooltip={"Supprimer"}
                                                onClickHandler={() => delBillingItem(e)}/>
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        </div>
        </React.Fragment>
    )
        ;
}

export default TableBillingItem;