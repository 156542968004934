import React, {useContext, useState} from "react";
import "./ContentCustomer.scss";
import {EventUpdatedContext} from "../../../store/EventUpdatedContext";
import {useFetchCustomers, DeleteCustomer} from "../../../service/Business/CustomerService";
import {getIdWithToken} from "../../../utils/storage/Token";
import FCSpinner from "../../FCObjets/FCSpinner";
import {faPen, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import FCIconButton from "../../FCObjets/FCIconButton";
import {TypeForm} from "../../../types/EnumTypeForm";
import {CustomerInterface} from "../../../service/Business/Interfaces/CustomerInterface";
import FCAlertMsgErrorAPI from "../../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";
import FCContentModalFL, {DataForm} from "../../FCObjets/Form/FCContentModalFL";
import FCFloatButton from "../../FCObjets/FCFloatButton";


function ContentCustomer() {

    let {cptUpdated, setUpdated} = useContext(EventUpdatedContext);
    const {data: customers, loading, error} = useFetchCustomers(getIdWithToken(), cptUpdated);
    const [isModalOpened,setIsModalOpened] = useState(false)
    const [data, setData] = useState<DataForm>({
        type: TypeForm.EMPTY,
        data: undefined,
    });

    // Permettant l'ouverture du modal
    const gestionModal = (open: boolean, isConfirm:boolean, e: CustomerInterface| any | undefined) => {
        let t = TypeForm.EMPTY;
        setIsModalOpened(open);
        if (open) {
            if (isConfirm){
                t =TypeForm.CONFIRM
            }
            else{
                t = TypeForm.CUSTOMER
            }
        }

        let r: DataForm = {
            type: t,
            data: e
        };

        setData(r)
    }


    return (
        <FCContentModalFL form={data} onModalFormFinished={() => {gestionModal(false, false, undefined); }}>
            <div className={"content-page-customer"}>
                <h1 className="content-heading">
                    Liste des clients

                </h1>
                {
                    !isModalOpened && <FCFloatButton items={[]} icon={faPlus} positionBottom={"70px"} handlerOnClick={() => gestionModal(true,false, undefined)}/>
                }
                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>Nom</th>
                        <th>N° Siret</th>
                        <th>Adresse</th>
                        <th>
                            <div className="row-content">
                                Actions &nbsp;

                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        loading && <tr>
                            <td><FCSpinner size={"middle"}/></td>
                        </tr>
                    }
                    {
                        customers && customers.map((e: CustomerInterface, index: React.Key) => {
                            return (<tr key={index}>
                                <td data-label="Nom">{e.companyName}</td>
                                <td data-label="N°Siret">{e.siret}</td>
                                <td data-label="Adresse">{e.address}</td>
                                <td data-label="Actions">
                                    <div className="row-content">
                                        <FCIconButton icon={faPen} tooltip={"Modifier"} color={"green"} onClickHandler={() => {
                                            gestionModal(true,false, e)
                                        }}/>
                                        <FCIconButton icon={faTrash} tooltip={"Supprimer"} color={"red"} onClickHandler={() => {
                                            const onConfirm = () =>{
                                                DeleteCustomer(getIdWithToken(), e).then(() => setUpdated())
                                            }
                                            const payload = {
                                                title:`Suppression de ${e.companyName}`,
                                                msg:"Êtes-vous sûr de vouloir supprimer ce client ?",
                                                onConfirm:onConfirm
                                            }
                                            gestionModal(true,true,payload);
                                        }}/>
                                    </div>
                                </td>
                            </tr>)
                        })}
                    {error && <tr>
                        <td>
                            <div>{error.message}</div>
                            <FCAlertMsgErrorAPI error={error}/>
                        </td>
                    </tr>}

                    </tbody>
                </table>
            </div>
        </FCContentModalFL>
    );
}

export default ContentCustomer;