import React, {useEffect, useState} from 'react';
import {AppSettings} from "../AppSettings";
import {getIdWithToken, getToken} from "../utils/storage/Token";
import axios from "axios";
import {useParams} from "react-router-dom";

const ViewPDF: React.FC = () => {

    const [src, setSrc] = useState("");
    const {type, id, path} = useParams();

    let url = `${AppSettings.API_ENDPOINT}${AppSettings.API_FREELANCERIE}/files/${type}/${getIdWithToken().toString()}&${id}`;
    if(path) {
        url = `${AppSettings.API_ENDPOINT}${AppSettings.API_FREELANCERIE}/explorer/view/${getIdWithToken().toString()}/${encodeURIComponent(path)}`;
    }

    useEffect(() => {
        axios(url, {
            method: 'GET',
            headers: {
                Authorization: getToken(),
                'Content-Type': 'application/pdf',
            },
            responseType: 'blob',
        }).then((response) => {
            return new Blob([response.data], {type: "application/pdf"})
        }).then((blob) => {
            setSrc(URL.createObjectURL(blob));
        }).catch((err) => {
            console.log(err);
        });
    }, [url]);

    return (
        <div style={{overflow:"hidden", width:"100%", height:"100vh"}}>
            <iframe style={{border:"none"}} title={`PDF type${type}`} width="100%" height="100%" src={src} />
        </div>
    );
};

export default ViewPDF;