export enum EnumTypePeriod {
    HOLIDAY = 0,
    URSSAF = 1,
}

class TypePeriod {
    public static ListType = [EnumTypePeriod.HOLIDAY];

    public static toString(type: EnumTypePeriod): string {
        switch (type) {
            case EnumTypePeriod.HOLIDAY:
                return "Vacance";
            case EnumTypePeriod.URSSAF:
                return "Echéance URSSAF";
        }
        return "Inconnu";
    }
}

export default TypePeriod;
