import {
    useGetRequest,
    RequestResponse,
    PutRequest,
    RequestPromise,
    PostRequest,
    DeleteRequest,
} from "../../hook/useRequest";
import {AppSettings} from "../../AppSettings";
import {getToken} from "../../utils/storage/Token";
import {PeriodInterface, PeriodInterfaceTPInterface} from "./Interfaces/CalendarInterface";

const API_URL_CALENDAR = AppSettings.API_FREELANCERIE + "/calendar";
const API_URL_CALENDAR_LIGHT = AppSettings.API_FREELANCERIE + "/calendar_light";

const useFetchDatesCalendar = (username: string, year: number, light: boolean, forceUpdated = 0): RequestResponse => {
    let config = {};
    if (!light) {
        config = {headers: {Authorization: getToken()}};
    }
    return useGetRequest(
        (light ? API_URL_CALENDAR_LIGHT : API_URL_CALENDAR) + `/${username}&${year}`,
        forceUpdated,
        config,
    );
};

const AddPeriod = (id_user: number, period: PeriodInterface): RequestPromise => {
    const config = {headers: {Authorization: getToken()}};
    return PutRequest(`${API_URL_CALENDAR}/period/${id_user}`, period, config);
};

const AddPeriodTP = (id_user: number, period: PeriodInterfaceTPInterface): RequestPromise => {
    const config = {headers: {Authorization: getToken()}};
    return PutRequest(`${API_URL_CALENDAR}/period/tp/${id_user}`, period, config);
};

const UpdatePeriod = (id_user: number, period: PeriodInterface): RequestPromise => {
    const config = {headers: {Authorization: getToken()}};
    return PostRequest(`${API_URL_CALENDAR}/period/${id_user}&${period.id}`, period, config);
};

const DeletePeriod = (id_user: number, period: PeriodInterface): RequestPromise => {
    const config = {headers: {Authorization: getToken()}};
    return DeleteRequest(`${API_URL_CALENDAR}/period/${id_user}&${period.id}`, config);
};

export {useFetchDatesCalendar, AddPeriod, UpdatePeriod, DeletePeriod, AddPeriodTP};
