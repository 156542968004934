import React, {useContext, useState} from "react";
import "./ContentResumeDeclaration.scss";
import {EventUpdatedContext} from "../../../../store/EventUpdatedContext";
import {useFetchDeclarationResume} from "../../../../service/Business/DeclarationService";
import {getIdWithToken} from "../../../../utils/storage/Token";
import FCSpinner from "../../../FCObjets/FCSpinner";
import FCIconButton from "../../../FCObjets/FCIconButton";
import {faCaretLeft, faCaretRight, faPen} from "@fortawesome/free-solid-svg-icons";
import FCAlertMsgErrorAPI from "../../../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";
import {DeclarationResumeInterface} from "../../../../service/Business/Interfaces/DeclarationInterface";
import FCTag from "../../../FCObjets/FCTag";
import StatusDeclaration from "../../../../utils/Business/StatusDeclaration";
import FCContentModalFL, {DataForm} from "../../../FCObjets/Form/FCContentModalFL";
import {TypeForm} from "../../../../types/EnumTypeForm";

function ContentResumeDeclaration() {

    const [year, setYear] = useState(new Date().getFullYear())

    const {cptUpdated, setUpdated} = useContext(EventUpdatedContext);
    const {data: resume, loading, error} = useFetchDeclarationResume(getIdWithToken(), year, cptUpdated);

    const [data, setData] = useState<DataForm>({
        type: TypeForm.EMPTY,
        data: undefined,
    });

    // Permettant l'ouverture du modal
    const gestionModal = (open: boolean, e: DeclarationResumeInterface | any | undefined, type: TypeForm | undefined) => {
        let r: DataForm = {
            type: TypeForm.EMPTY,
            data: e
        };
        if (open && type) r.type = type
        setData(r)
    }

    return (
        <FCContentModalFL form={data} onModalFormFinished={() => {
            gestionModal(false, undefined, undefined);
            setUpdated();
        }}>
            <div className={"content-resume-declaration"}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center"
                }}>
                    <FCIconButton icon={faCaretLeft} onClickHandler={() => setYear(year - 1)} tooltip={""}/>
                    <h1 className="content-heading">
                        Résumé des déclarations {year}
                    </h1>
                    <FCIconButton icon={faCaretRight} onClickHandler={() => setYear(year + 1)} tooltip={""}/>
                </div>
                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>Description</th>
                        <th>Date de déclaration</th>
                        <th>CA à déclarer</th>
                        <th>CFE</th>
                        <th>CPBNC</th>
                        <th>VL</th>
                        <th>Total URSSAF</th>
                        <th>Statut</th>
                        <th>
                            <div className="row-content">
                                Actions &nbsp;
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        loading && <tr>
                            <td><FCSpinner size={"middle"}/></td>
                        </tr>
                    }
                    {
                        resume && resume.map((e: DeclarationResumeInterface, index: React.Key) => {
                            return (<tr key={index}>
                                <td data-label="Description">{e.desc}</td>
                                <td data-label="Date de déclaration">{new Date(e.date).toLocaleDateString()}</td>
                                <td data-label="CA à déclarer">{e.ca}</td>
                                <td data-label="CFE">{e.cfe}</td>
                                <td data-label="CPBNC">{e.cpbnc}</td>
                                <td data-label="VL">{e.vl}</td>
                                <td data-label="Total URSSAF">{e.total_urssaf}</td>
                                <td data-label="Statut">
                                    <FCTag value={StatusDeclaration.toString(e.state)}
                                           color={StatusDeclaration.toColor(e.state)}
                                           style={{fontWeight: "bold"}}/>
                                </td>
                                <td data-label="Actions">
                                    <div className="row-content">
                                        <FCIconButton icon={faPen} tooltip={"Modifier"} color={"green"}
                                                      onClickHandler={() => {
                                                          gestionModal(true, e, TypeForm.STATE_DECLARATION_URSSAF)
                                                      }}/>
                                    </div>
                                </td>
                            </tr>)
                        })
                    }
                    {error && <tr>
                        <td>
                            <div>{error.message}</div>
                            <FCAlertMsgErrorAPI error={error}/>
                        </td>
                    </tr>
                    }

                    </tbody>
                </table>
            </div>
        </FCContentModalFL>
    );
}

export default ContentResumeDeclaration;