import React, {ReactNode} from "react";
import "./FCTooltip.scss"

type TooltipProps = {
    children:ReactNode;
};

const FCTooltip: React.FunctionComponent<TooltipProps> = (props: TooltipProps) => {

    return (
        <span className="tooltiptext">
            {props.children}
        </span>
    );
}

export default FCTooltip;