import React from "react";
import "./Calendar.scss"
import TypeDate from "../../../types/TypeDate";
import CalendarDay from "./CalendarDay";
import {CalendarInterface, PeriodInterface} from "../../../service/Business/Interfaces/CalendarInterface";

type CalendarMonthProps = {
    month: number;
    year: number;
    data: CalendarInterface;
    editable: boolean;

    onClickEventDay: (data: PeriodInterface) => void;
};

const CalendarMonth: React.FunctionComponent<CalendarMonthProps> = ({month, year, data, editable, onClickEventDay}) => {

    let numberDaysInMonth = new Date(year, month, 0).getDate();
    let numDayOnWeekForFirstDate = new Date(year, month - 1, 1).getDay();

    return (

        <div className={"divCalendarMonth"}>
            <div className={"divNameMonthAndDays"}>
                <div className={"title"}>
                    {TypeDate.monthsLong[month - 1] + " " + year}
                </div>
                <div className={"divMonthAndAllDay"}>
                    {
                        Array.apply(0, Array(8)).map(function (x, lineMonth) {
                            if (lineMonth === 0) {
                                return (
                                    <div key={lineMonth} style={{display: "flex"}}>
                                        {
                                            ["L", "M", "M", "J", "V", "S", "D"].map(function (x, index) {
                                                return (
                                                    <CalendarDay editable={editable} key={index} forceText={x}
                                                                 datasBDD={data} title={true}
                                                                 onEventClick={onClickEventDay}/>
                                                );
                                            })
                                        }
                                    </div>
                                )
                            }

                            return (
                                <div key={lineMonth} style={{display: "flex"}}>
                                    {
                                        Array.apply(0, Array(7)).map(function (x, day) {
                                            let d = ((lineMonth - 1) * 7 + day) + 1;

                                            let numDayOnWeek = numDayOnWeekForFirstDate === 0 ? 6 : numDayOnWeekForFirstDate - 1;

                                            if (d >= numberDaysInMonth + numDayOnWeek + 1) {
                                                return null;
                                            }

                                            let nameDay = d - numDayOnWeek;

                                            return (
                                                <CalendarDay editable={editable} key={d}
                                                             date={new Date(year, month - 1, nameDay)}
                                                             hide={d <= numDayOnWeek} datasBDD={data}
                                                             onEventClick={onClickEventDay}/>
                                            )
                                        })
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default CalendarMonth;