import React, {useState} from "react";
import FCButton from "../../FCObjets/FCButton";
import {getIdWithToken} from "../../../utils/storage/Token";
import {CallbackErrorModalFL, CallbackFinishedModalFL} from "../../Dashboard/Form/ModalForm";
import FCDropdown, {SelectOption} from "../../FCObjets/Form/FCDropdown";
import {
    DeclarationResumeInterface,
    DeclarationURSSAFChangeStateInterface
} from "../../../service/Business/Interfaces/DeclarationInterface";
import StatusDeclaration from "../../../utils/Business/StatusDeclaration";
import {UpdateChangeStatusDeclarationURSSAF} from "../../../service/Business/DeclarationService";

type FormDeclarationURSSAFProps = {
    onFinished: CallbackFinishedModalFL;
    onError: CallbackErrorModalFL;
    decURSSAF: DeclarationResumeInterface;
};

const FormDeclarationURSSAF: React.FunctionComponent<FormDeclarationURSSAFProps> = (props) => {
    const [inputState, setInputState] = useState(props.decURSSAF.state);

    // Création des options pour la liste des status
    let optionsState: SelectOption[] = [];
    for (let i = 0; i < StatusDeclaration.ListStatus.length; i++) {
        optionsState.push({
            value: StatusDeclaration.ListStatus[i].toString(),
            label: StatusDeclaration.toString(StatusDeclaration.ListStatus[i])
        });
    }

    /**
     * Validation du formulaire
     */
    const handleSubmit = () => {
        props.onError(null);
        let dec: DeclarationURSSAFChangeStateInterface = {
            idDeclaration: props.decURSSAF.idDeclaration,
            state: inputState,
            numDeclaration: props.decURSSAF.num_declaration,
            year: props.decURSSAF.year
        };

        UpdateChangeStatusDeclarationURSSAF(getIdWithToken(), dec).then(() => {
            props.onFinished();
        }).catch((err) => props.onError(err));
    }

    return (
        <form>
            <div style={{minWidth: "350px", minHeight: "250px"}}>
                <FCDropdown onChangeHandler={(e) => setInputState(parseInt(e.target.value))}
                            options={optionsState}
                            label={"Statut : "} id={"inputState"} value={inputState}
                            widthDropdown="100%"/>
            </div>
            <div
                style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignSelf: "flex-end", alignItems: "baseline"}}>
                <FCButton label={"Valider"} type={"button"} onClickHandler={handleSubmit}
                          style={{width: "60px", height: "30px", verticalAlign: "middle"}}/>
            </div>
        </form>
    );
}

export default FormDeclarationURSSAF;