import { PostRequest, DeleteRequest, RequestPromise, PutRequest } from "../../hook/useRequest";
import { AppSettings } from "../../AppSettings";
import { getToken } from "../../utils/storage/Token";
import { BillingItemInterface } from "./Interfaces/BillingInterface";

const API_URL_BILLING_ITEM = AppSettings.API_FREELANCERIE + "/billing/item";

const UpdateBillingItem = (
    id_billing: number,
    bilItem: { listBillingItem: BillingItemInterface[] },
): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return PutRequest(`${API_URL_BILLING_ITEM}/${id_billing.toString()}`, bilItem, config);
};

const AddBillingItem = (id_billing: number, bilItem: { listBillingItem: BillingItemInterface[] }): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return PostRequest(`${API_URL_BILLING_ITEM}/${id_billing.toString()}`, bilItem, config);
};

const DeleteBillingItem = (bilItem: BillingItemInterface): RequestPromise => {
    const config = { headers: { Authorization: getToken() } };
    return DeleteRequest(`${API_URL_BILLING_ITEM}/${bilItem.id.toString()}`, config);
};

export { UpdateBillingItem, AddBillingItem, DeleteBillingItem };
