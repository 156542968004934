import React from "react";
import {useNavigate} from "react-router-dom";
import {CardsInterface} from "../../service/Interfaces/CardInterface";
import "./Card.scss";

type CardProps = {
    dataCard: CardsInterface;
};

function Card(props: CardProps) {

    const navigate = useNavigate();
    const handleOnClickCV = (url:string) => {
        window.open(url, "_blank");
    }

    const handleOnClickCalendar = () => {
        navigate(`planning`);
    }

    return (
        <div className="fcCard">
            <div className="fcCardContentHead">

                <img className="fcCardImg" src={"https://www.gravatar.com/avatar/" + props.dataCard.avatar + "?s=200"}
                     alt="gravatar"/>

                <div className="fcCardHeader">
                    <div className="fcCardLabel">
                        {props.dataCard.lastname + " " + props.dataCard.firstname}
                    </div>
                    <div className="fcCardDescription">
                        {props.dataCard.title}
                    </div>
                    <div className="fcCardSeparator"></div>
                </div>

            </div>
            <div className="fcCardFooter">
                <div className="fcCardContent">
                    {props.dataCard.urlCV !== "" ?
                        <span className="material-symbols-outlined" style={{cursor: "pointer", marginRight: "15px"}}
                              onClick={() => handleOnClickCV(props.dataCard.urlCV)}>description</span> : null}
                    <span className="material-symbols-outlined" style={{cursor: "pointer"}}
                          onClick={() => handleOnClickCalendar()}>calendar_month</span>
                </div>
            </div>
        </div>
    )
}

export default Card;