import React, {useState} from 'react';

import FormAccount from "../../Form/FormAccount/FormAccount";
import {getIdWithToken} from "../../../../utils/storage/Token";
import FCAlertMsgErrorAPI from "../../../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";

const ContentAccount: React.FC = () => {

    const [error, setError] = useState(undefined);

    return (
        <React.Fragment>
            <h1 className="content-heading"> Configuration </h1>
            <div>
                <FormAccount onFinished={() => {
                    alert("Données mise à jour");
                    window.location.reload();
                }} idUser={getIdWithToken()}  onError={(e) => setError(e)}/>
            </div>
            {error &&
                <FCAlertMsgErrorAPI error={error}/>
            }
        </React.Fragment>
    );
};

export default ContentAccount;
