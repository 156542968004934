import React from "react";
import "./ContentHelpDeclaration.scss";
import {AppSettings} from "../../../../AppSettings";

function ContentHelpDeclaration() {

    return (
        <div className={"imagesHelp"}>
            <div className={"imageUrssaf"}>
                <img src={`${AppSettings.API_ENDPOINT}/images/image/aide_urssaf.png`}
                     alt={"aide_urssaf"} />
            </div>
            <div className={"imageUrssaf"}>
                <img src={`${AppSettings.API_ENDPOINT}/images/image/aide_tva.png`}
                     alt={"aide_tva"} />
            </div>
        </div>
    );
}

export default ContentHelpDeclaration;