import React, {useEffect, useState} from "react";
import FCInput from "../../FCObjets/Form/FCInput";
import FCButton from "../../FCObjets/FCButton";
import FCDropdown from "../../FCObjets/Form/FCDropdown";
import {BusinessDealInterface} from "../../../service/Business/Interfaces/BusinessDealInterface";
import StatusBusinessDeal from "../../../utils/Business/StatusBusinessDeal";
import FCDatePicker from "../../FCObjets/Form/FCDatePicker";
import {AddBusiness, UpdateBusiness} from "../../../service/Business/BusinessService";
import {useFetchCustomers} from "../../../service/Business/CustomerService";
import {getIdWithToken} from "../../../utils/storage/Token";
import FCSpinner from "../../FCObjets/FCSpinner";
import {CallbackErrorModalFL, CallbackFinishedModalFL} from "../../Dashboard/Form/ModalForm";
import FCAlertMsgErrorAPI from "../../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";
import { useFetchConfigUser } from "../../../service/AccountService";

type FormBusinessProps = {
    onFinished: CallbackFinishedModalFL;
    onError: CallbackErrorModalFL;
    dataBusiness: BusinessDealInterface | undefined;
};

const FormBusinessDeal: React.FunctionComponent<FormBusinessProps> = ({dataBusiness, onFinished, onError}) => {
    const {data: configUser} = useFetchConfigUser(getIdWithToken());
    const [inputCustomer, setInputCustomer] = useState(dataBusiness ? dataBusiness.id : 0);
    const [inputName, setInputName] = useState(dataBusiness ? dataBusiness.name : "");
    const [inputDescription, setInputDescription] = useState(dataBusiness ? dataBusiness.description : "");
    const [inputStatus, setInputStatus] = useState(dataBusiness ? dataBusiness.state : 0);
    const [inputTJM, setInputTJM] = useState(dataBusiness ? dataBusiness.details.tjm :0);
    const [inputDayExpected, setInputDayExpected] = useState(dataBusiness ? dataBusiness.details.totalOfDaysExpected : 0);
    const [dateBegin, setDateBegin] = useState(dataBusiness ? new Date(dataBusiness.details.begin) : null);
    const [dateEnd, setDateEnd] = useState(dataBusiness ? new Date(dataBusiness.details.end) : null);
    const [inputNumber, setInputNumber] = useState(dataBusiness ? dataBusiness.details.number : 0);
    const [inputNumAffaire, setInputNumAffaire] = useState(dataBusiness ? dataBusiness.details.numAffaire : "");

    // Chargement du TJM par defaut défini dans les settings utilisateur.
    useEffect(() => {
        if (configUser) {
            setInputTJM(configUser.user.userInfo.tjmDefault);
        }
    }, [configUser]);


    const options = []
    for (let i = 0; i < StatusBusinessDeal.ListStatus.length; i++) {
        options.push({
            value: StatusBusinessDeal.ListStatus[i].toString(),
            label: StatusBusinessDeal.toString(StatusBusinessDeal.ListStatus[i])
        });
    }

    const {data: customers, loading, error} = useFetchCustomers(getIdWithToken());
    const optionsCustomer = []
    if (customers) {
        for (let i = 0; i < customers.length; i++) {
            optionsCustomer.push({value: i.toString(), label: customers[i].companyName});
        }
    }

    /**
     * Validation du formulaire
     */
    const handleSubmit = () => {
        onError(null);

        let customer = dataBusiness?.customer;
        if (customers) {
            customer = customers[inputCustomer];
        }

        let business: any = {
            customer: customer,
            description: inputDescription,
            details: {
                id: dataBusiness ? dataBusiness.details.id : -1,
                tjm: inputTJM,
                begin: dateBegin?.toDateString(),
                end: dateEnd?.toDateString(),
                totalOfDaysExpected: inputDayExpected,
                number: inputNumber,
                numAffaire: inputNumAffaire,
            },
            id: dataBusiness ? dataBusiness.id : -1,
            name: inputName,
            state: inputStatus,
        };

        if (dataBusiness) {
            UpdateBusiness(dataBusiness.id, business).then(() => {
                onFinished();
            }).catch((err) => onError(err));
        } else {
            AddBusiness(business).then(() => {
                onFinished();
            }).catch((err) => onError(err));
        }
    }

    return (
        <form>
            <div>
                <React.Fragment>
                    {loading && <FCSpinner size={"small"}/>}
                    {customers && <FCDropdown onChangeHandler={(e) => setInputCustomer(parseInt(e.target.value))}
                                              options={optionsCustomer}
                                              label={"Client"} id={"client"} value={inputCustomer}
                                              widthDropdown={"361px"}/>}
                    {error &&
                        <div>
                            {error.message}
                            <FCAlertMsgErrorAPI error={error}/>
                        </div>
                    }
                </React.Fragment>

                <FCInput onChangeHandler={(e) => setInputName(e.target.value)} label={"Nom"} id={"nom"}
                         value={inputName} widthInput={"350px"}/>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>

                    <FCInput onChangeHandler={(e) => setInputNumber(parseInt(e.target.value))} label={"Numéro"} id={"num"}
                            value={inputNumber} widthInput={"150px"} type={"number"}/>
                    <FCInput onChangeHandler={(e) => setInputNumAffaire(e.target.value)} label={"Num. Affaire"}
                            id={"numAff"}
                            value={inputNumAffaire} widthInput={"150px"}/>
                </div>
                <FCInput onChangeHandler={(e) => setInputDescription(e.target.value)} label={"Description"}
                         id={"description"}
                         value={inputDescription} widthInput={"350px"}/>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                    <FCInput onChangeHandler={(e) => setInputTJM(parseInt(e.target.value))} label={"TJM"} id={"tjm"}
                            value={inputTJM} widthInput={"150px"} type={"number"}/>
                    <FCInput onChangeHandler={(e) => setInputDayExpected(parseInt(e.target.value))}
                            label={"Nombre de jours"} id={"numberDays"}
                            value={inputDayExpected} widthInput={"150px"} widthLabel="150px" type={"number"}/>
                </div>
                <FCDropdown onChangeHandler={(e) => setInputStatus(parseInt(e.target.value))} options={options}
                            label={"Status"} id={"Status"} value={inputStatus}
                            widthDropdown={"361px"}/>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                    <FCDatePicker onChangeHandler={setDateBegin} value={dateBegin} id={"deb"} label={"Début"}/>
                    <FCDatePicker onChangeHandler={setDateEnd} value={dateEnd} id={"fin"} label={"Fin"}/>
                </div>
            </div>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between",alignSelf:"flex-end",alignItems:"baseline"}}>
                <FCButton label={"Valider"} type={"button"} onClickHandler={handleSubmit} style={{width: "60px"}}/>
            </div>
        </form>
    );
}

export default FormBusinessDeal;