import React, {useState} from "react";
import FCInput from "../../FCObjets/Form/FCInput";
import FCButton from "../../FCObjets/FCButton";
import FCDatePicker from "../../FCObjets/Form/FCDatePicker";
import {CallbackErrorModalFL, CallbackFinishedModalFL} from "../../Dashboard/Form/ModalForm";
import {ExpenseDBInterface} from "../../../service/Business/Interfaces/ExpenseInterface";
import {AddExpense, UpdateExpense} from "../../../service/Business/ExpenseService";
import {getIdWithToken} from "../../../utils/storage/Token";
import FCCheckbox from "../../FCObjets/Form/FCCheckbox";

type FormBillingProps = {
    onFinished: CallbackFinishedModalFL;
    onError: CallbackErrorModalFL;
    dataExpense: ExpenseDBInterface | undefined;
};

const FormExpense: React.FunctionComponent<FormBillingProps> = ({dataExpense, onFinished, onError}) => {

    const [dateExpense, setDateExpense] = useState(dataExpense ? new Date(dataExpense.date) : null);
    const [inputDescription, setInputDescription] = useState(dataExpense ? dataExpense.description : "");
    const [inputPriceInclTax, setInputPriceInclTax] = useState(dataExpense ? dataExpense.priceInclTax.toString() : "0");
    const [inputVat, setInputVat] = useState(dataExpense ? (dataExpense.vat * 100).toString() : "0");
    const [inputCopyForYear, setInputCopyForYear] = useState(false);
    const [inputCompteFournisseur, setInputCompteFournisseur] = useState(dataExpense ? dataExpense.receiptLoaded === 2 : false);

    /**
     * Validation du formulaire
     */
    const handleSubmit = () => {
        onError(null);

        let expense: ExpenseDBInterface = {
            userCnx: undefined,
            date: dateExpense ? dateExpense.toDateString() : "",
            description: inputDescription,
            id: dataExpense ? dataExpense.id : -1,
            priceInclTax: parseFloat(inputPriceInclTax),
            vat: parseFloat(inputVat) / 100,
            copyForYear: inputCopyForYear,
            compteFournisseur: inputCompteFournisseur,
            receiptLoaded : dataExpense ? dataExpense.receiptLoaded : 0
        };

        if (dataExpense) {
            UpdateExpense(expense.id, expense).then(() => {
                onFinished();
            }).catch((err) => onError(err));
        } else {
            AddExpense(getIdWithToken(), expense).then(() => {
                onFinished();
            }).catch((err) => onError(err));
        }
    }

    console.log(inputCompteFournisseur);
    return (
        <form>
            <div>
                <FCInput onChangeHandler={(e) => setInputDescription(e.target.value)} label={"Description"} id={"desc"}
                         value={inputDescription} widthInput={"300px"} type={"text"}/>

                <FCInput value={inputPriceInclTax} type={"text"} widthInput={"300px"} id={"priceInclTax"}
                         label={"Prix TTC"}
                         min={0} max={1} step={"0.01"} pattern="[0-1]{1}([,\.][0-9]{0,2})?"
                         onChangeHandler={(event) => {
                             const value = event.target.value.replaceAll(',', ".");
                             const numberVal = parseFloat(value);
                             if (!isNaN(numberVal)) {
                                 setInputPriceInclTax(value);
                             }
                         }}/>
                <FCInput value={inputVat} type={"text"} widthInput={"300px"} id={"vat"} label={"TVA"}
                         min={0} max={1} step={"0.01"} pattern="[0-1]{1}([,\.][0-9]{0,2})?"
                         onChangeHandler={(event) => {
                             const value = event.target.value.replaceAll(',', ".");
                             const numberVal = parseFloat(value);
                             if (!isNaN(numberVal) && numberVal >= 0 && numberVal <= 100) {
                                 setInputVat(value);
                             }
                         }}/>
                <FCDatePicker onChangeHandler={setDateExpense} value={dateExpense} id={"date"} label={"Date"}/>

                <FCCheckbox onChangeHandler={(e) => setInputCompteFournisseur(e.target.checked)}
                            label={"Justificatif compte fournisseur"} id={"compteFournisseur"}
                            value={inputCompteFournisseur} widthLabel={"200px"}/>


                {dataExpense === undefined &&
                    <FCCheckbox onChangeHandler={(e) => setInputCopyForYear(e.target.checked)}
                                label={"Copier la dépense pour l'année en cours"} id={"copyForYear"}
                                value={inputCopyForYear} widthLabel={"200px"}/>
                }
            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignSelf: "flex-end",
                    alignItems: "baseline"
                }}>
                <FCButton label={"Valider"} type={"button"} onClickHandler={handleSubmit} style={{width: "60px"}}/>
            </div>

        </form>
    );
}

export default FormExpense;