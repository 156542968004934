import React, {useEffect, useState} from 'react';
import "./FormAccount.scss"
import {UpdateUserInfo, useFetchConfigUser} from "../../../../service/AccountService";
import jwt from "jsonwebtoken";
import FCButton from "../../../FCObjets/FCButton";
import {CallbackErrorModalFL, CallbackFinishedModalFL} from "../ModalForm";
import FCSpinner from "../../../FCObjets/FCSpinner";
import FormAccountUser, {InterfaceAccountUser} from "./FormAccountUser";
import FormAccountMailing, {InterfaceAccountMailing} from "./FormAccountMailing";
import FormAccountEnterprise, {InterfaceAccountEnterprise} from "./FormAccountEnterprise";
import FormAccountAdmin, {InterfaceAccountAdmin} from "./FormAccountAdmin";
import {getIdWithToken, isAdminWithToken} from "../../../../utils/storage/Token";

type FormAccountListProps = {
    onFinished: CallbackFinishedModalFL;
    onError: CallbackErrorModalFL;
    idUser: number;
};

const FormAccount: React.FC<FormAccountListProps> = ({idUser, onFinished, onError}) => {
    const [ongletActif, setOngletActif] = useState<string>('Utilisateur');

    const handleOngletClick = (onglet: string) => {
        setOngletActif(onglet);
    };

    const {data: configUser, loading, error} = useFetchConfigUser(idUser);
    const [dataUser, setDataUser] = useState<InterfaceAccountUser>();
    const [dataMailing, setDataMailing] = useState<InterfaceAccountMailing>();
    const [dataEnterprise, setDataEnterprise] = useState<InterfaceAccountEnterprise>();
    const [dataAdmin, setDataAdmin] = useState<InterfaceAccountAdmin>();

    useEffect(() => {
        if (configUser) {
            setDataUser({
                civility: configUser.user.civility,
                firstname: configUser.user.firstname,
                lastname: configUser.user.lastname,
                titleJob: configUser.user.userInfo.titleJob,
                urlCV: configUser.user.userInfo.urlCV,
                contactEmail: configUser.user.contactEmail
            })

            setDataEnterprise({
                companyLocation: configUser.user.userInfo.companyLocation,
                companyName: configUser.user.userInfo.companyName,
                companySiret: configUser.user.userInfo.companySiret,
                declarationLocation: configUser.user.userInfo.declarationLocation,
                tjm: configUser.user.userInfo.tjmDefault,
                logo: configUser.user.userInfo.logo,
                vl: configUser.user.userInfo.vl
            })

            setDataAdmin({
                role: configUser.user.role,
                stateAccount: configUser.user.stateAccount
            })

            setDataMailing({
                serverSMTP: configUser.user.userInfo.nameServerSMTP,
                userSmtp: configUser.user.userInfo.userSMTP,
                passSMTP: ""
            })
        }

        if (error) {
            onError(error)
        }
    }, [error, configUser]);

    /**
     * Validation du formulaire
     */
    const handleSubmit = () => {
        let userInfo: {} = {
            id: configUser.user.id,
            firstname: dataUser?.firstname,
            lastname: dataUser?.lastname,
            contactEmail: dataUser?.contactEmail,
            civility: dataUser?.civility,

            userInfo: {
                id: configUser.user.userInfo.id,
                userSMTP: dataMailing?.userSmtp,
                nameServerSMTP: dataMailing?.serverSMTP,
                passSMTP: dataMailing?.passSMTP !== "" ? jwt.sign({passSmtp: dataMailing?.passSMTP}, "privateKeyPassSMTP") : undefined,
                tjmDefault: dataEnterprise?.tjm,
                companyName: dataEnterprise?.companyName,
                companySiret: dataEnterprise?.companySiret,
                declarationLocation: dataEnterprise?.declarationLocation,
                companyLocation: dataEnterprise?.companyLocation,
                titleJob: dataUser?.titleJob,
                urlCV: dataUser?.urlCV,
                logo: dataEnterprise?.logo,
                vl: dataEnterprise?.vl
            }
        };

        if (isAdminWithToken()) {
            userInfo = {...userInfo, role: dataAdmin?.role, stateAccount: dataAdmin?.stateAccount};
        }

        UpdateUserInfo(getIdWithToken(), userInfo).then(() => {
            onFinished();
        }).catch((err) => onError(err));
    }

    return (
        <React.Fragment>
            <div>
                <div className="onglet-container">
                    <div className={`onglet ${ongletActif === 'Utilisateur' ? 'active' : ''}`}
                         onClick={() => handleOngletClick('Utilisateur')}>Utilisateur
                    </div>
                    <div className={`onglet ${ongletActif === 'Entreprise' ? 'active' : ''}`}
                         onClick={() => handleOngletClick('Entreprise')}>Entreprise
                    </div>
                    <div className={`onglet ${ongletActif === 'Mailing' ? 'active' : ''}`}
                         onClick={() => handleOngletClick('Mailing')}>Mailing
                    </div>
                    {
                        isAdminWithToken() &&
                        <div className={`onglet ${ongletActif === 'Admin' ? 'active' : ''}`}
                             onClick={() => handleOngletClick('Admin')}>Admin
                        </div>
                    }
                </div>
                <div className="contenu-onglets">
                    <form className={"content-page-account"}>
                        {loading && <FCSpinner size={"large"}/>}
                        {(dataUser && ongletActif === 'Utilisateur') && (
                            <div>
                                <FormAccountUser handlerChange={(user) => setDataUser(user)} data={dataUser}/>
                            </div>
                        )}
                        {(dataEnterprise && ongletActif === 'Entreprise') && (
                            <div>
                                <FormAccountEnterprise handlerChange={(enterprise) => setDataEnterprise(enterprise)}
                                                       data={dataEnterprise}/>
                            </div>
                        )}
                        {(dataMailing && configUser && ongletActif === 'Mailing') && (
                            <div>
                                <FormAccountMailing handlerChange={(mail) => setDataMailing(mail)} data={dataMailing}
                                                    listServerSmtp={configUser.listServerSmtp}/>
                            </div>
                        )}

                        {(isAdminWithToken() && dataAdmin && ongletActif === 'Admin') && (
                            <div>
                                <FormAccountAdmin handlerChange={(admin) => setDataAdmin(admin)} data={dataAdmin}/>
                            </div>
                        )}
                        <div style={{textAlign: "right", marginBottom: "15px", marginRight: "15px"}}>
                            <FCButton label={"Valider"} type={"button"} onClickHandler={handleSubmit}
                                      style={{width: "60px"}}/>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
};

export default FormAccount;
