import React, {useState} from 'react';
import FCDropdown, {SelectOption} from "../../../components/FCObjets/Form/FCDropdown";
import FCInput from "../../../components/FCObjets/Form/FCInput";
import "./CreateAccountUser.scss";
import {GetUsernameFree} from "../../../service/AccountService";

export interface InterfaceCreateAccountUser {
    username: string;
    firstname: string;
    lastname: string;
    password: string;
    civility: string;
    titleJob: string;
    urlCV: string;
}

export type CreateAccountUserProps = {
    handlerChange: (dataUser: InterfaceCreateAccountUser) => void;
    verifyInput: boolean
}

const CreateAccountUser: React.FC<CreateAccountUserProps> = (props: CreateAccountUserProps) => {
    const [inputPassword, setInputPassword] = useState("");
    const [inputPasswordVerify, setInputPasswordVerify] = useState("");
    const [diffPassword, setDiffPassword] = useState(false);

    const [usernameFree, setUsernameFree] = useState(true);
    const [inputUsername, setInputUsername] = useState("");

    const [dataUser, setDataUser] = useState<InterfaceCreateAccountUser>({
        civility: "", firstname: "", lastname: "", password: "", titleJob: "", urlCV: "", username: ""
    });

    let optionCivility: SelectOption[] = [];
    optionCivility.push({value: "", label: "-------"});
    optionCivility.push({value: "Monsieur", label: "Monsieur"});
    optionCivility.push({value: "Madame", label: "Madame"});
    optionCivility.push({value: "Non binaire", label: "Non binaire"});

    const eventChange = (key: "firstname" | "lastname" | "civility" | "titleJob" | "urlCV" | "username" | "password", value: string) => {
        let v: InterfaceCreateAccountUser = {
            username: dataUser.username,
            firstname: dataUser.firstname,
            lastname: dataUser.lastname,
            password: dataUser.password,
            civility: dataUser.civility,
            titleJob: dataUser.titleJob,
            urlCV: dataUser.urlCV
        }
        v[key] = value;
        setDataUser(v);
        props.handlerChange(v);
    }

    const eventUsernameChanged = async (username: string) => {

        if(username.length > 0) {
            try {
                const response = await GetUsernameFree(username);
                const {free} = response.data;

                if (free) {
                    eventChange("username", username);
                } else {
                    eventChange("username", "");
                }

                setInputUsername(username);
                setUsernameFree(free);

            } catch (error) {
                console.error(error);
                setInputUsername("");
                eventChange("username", "");
            }
        }
        else {
            setInputUsername("");
            eventChange("username", "");
        }
    }

    /**
     * Évènement de mise à jour des champs
     */
    const eventChangePass = (value: string, inputVerify: boolean) => {

        if (inputVerify) {
            setInputPasswordVerify(value);
        } else {
            setInputPassword(value);
        }

        if ((inputVerify && value !== inputPassword) ||
            (!inputVerify && value !== inputPasswordVerify)) {
            setDiffPassword(true);
        } else {
            setDiffPassword(false);
            eventChange("password", inputPassword);
        }
    }

    return (
        <div className="createaccountuserview">
            <div className={"title-createaccountuserview"}>Données utilisateurs</div>
            <div className={"form-createaccountuserview"}>
                <FCInput onChangeHandler={(e) => eventUsernameChanged(e.target.value)}
                         label={"Username *"} id={"username"}
                         value={inputUsername} widthInput={"300px"} colorTextLabel={"#FFFFFF"} widthLabel={"auto"}
                         backgroundColor={(props.verifyInput && dataUser.username == "" ) || (!usernameFree) ? "#E74C3C" : undefined}/>

                <FCDropdown onChangeHandler={(e) => eventChange("civility", e.target.value)}
                            options={optionCivility} label={"Civilité *"} id={"civility"} value={dataUser.civility}
                            widthDropdown={"311px"} colorTextLabel={"#FFFFFF"} widthLabel={"200px"}
                            backgroundColor={props.verifyInput && dataUser.civility == "" ? "#E74C3C" : undefined}/>

                <FCInput onChangeHandler={(e) => eventChange("firstname", e.target.value)}
                         label={"Prénom *"} id={"prenom"} value={dataUser.firstname} widthInput={"300px"}
                         colorTextLabel={"#FFFFFF"} widthLabel={"auto"}
                         backgroundColor={props.verifyInput && dataUser.firstname == "" ? "#E74C3C" : undefined}/>

                <FCInput onChangeHandler={(e) => eventChange("lastname", e.target.value)}
                         label={"Nom *"} id={"nom"} value={dataUser.lastname} widthInput={"300px"}
                         colorTextLabel={"#FFFFFF"} widthLabel={"auto"}
                         backgroundColor={props.verifyInput && dataUser.lastname == "" ? "#E74C3C" : undefined}/>

                <FCInput onChangeHandler={(e) => eventChangePass(e.target.value, false)} label={"Mot de passe *"}
                         id={"pass"} value={inputPassword} widthInput={"300px"} colorTextLabel={"#FFFFFF"}
                         colorInput={diffPassword ? "#FFFFFF" : undefined} type={"password"} widthLabel={"auto"}
                         backgroundColor={diffPassword || (props.verifyInput && inputPassword == "") ? "#E74C3C" : undefined}/>

                <FCInput onChangeHandler={(e) => eventChangePass(e.target.value, true)}
                         label={"Vérification mot de passe *"} id={"pass1"}
                         value={inputPasswordVerify} widthInput={"300px"} colorTextLabel={"#FFFFFF"}
                         colorInput={diffPassword ? "#FFFFFF" : undefined} type={"password"} widthLabel={"auto"}
                         backgroundColor={diffPassword || (props.verifyInput && inputPasswordVerify == "") ? "#E74C3C" : undefined}/>

                <FCInput onChangeHandler={(e) => eventChange("titleJob", e.target.value)}
                         label={"Poste/status dans l'entreprise"} id={"titlejob"} value={dataUser.titleJob}
                         widthInput={"300px"} colorTextLabel={"#FFFFFF"} widthLabel={"auto"}/>

                <FCInput onChangeHandler={(e) => eventChange("urlCV", e.target.value)}
                         label={"URL CV"} id={"urlCV"}
                         value={dataUser.urlCV} widthInput={"300px"} colorTextLabel={"#FFFFFF"} widthLabel={"auto"}/>
            </div>
        </div>
    );
};

export default CreateAccountUser;