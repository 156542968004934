import React, {useContext, useState} from "react";
import "./Calendar.scss"
import CalendarMonth from "./CalendarMonth";
import {useFetchDatesCalendar} from "../../../service/Business/CalendarService";
import FCSpinner from "../../FCObjets/FCSpinner";
import {faCaretLeft, faCaretRight, faPlus} from "@fortawesome/free-solid-svg-icons";
import FCFloatButton from "../../FCObjets/FCFloatButton";
import {TypeForm} from "../../../types/EnumTypeForm";
import {EventUpdatedContext} from "../../../store/EventUpdatedContext";
import FCAlertMsgErrorAPI from "../../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";
import {PeriodInterface} from "../../../service/Business/Interfaces/CalendarInterface";
import FCContentModalFL, {DataForm} from "../../FCObjets/Form/FCContentModalFL";
import FCIconButton from "../../FCObjets/FCIconButton";

type ContentCalendarProps = {
    editable: boolean;
    username: string;
};

const ContentCalendar: React.FunctionComponent<ContentCalendarProps> = (props) => {

    const {cptUpdated} = useContext(EventUpdatedContext);
    const [isModalOpened,setIsModalOpened] = useState(false)
    const [year, setYear] = useState(new Date().getFullYear())
    const {data: dates, loading, error} = useFetchDatesCalendar(props.username, year, !props.editable, cptUpdated);

    /**
     * Gestion de l'évènement de click
     * @param dataPeriod donnée de la période
     */
    function clickEventDay(dataPeriod: PeriodInterface): void {
        gestionModal(true, dataPeriod, TypeForm.PERIOD)
    }

    const [data, setData] = useState<DataForm>({
        type: TypeForm.EMPTY,
        data: undefined,
    });

    // Permettant l'ouverture du modal
    const gestionModal = (open: boolean, e: PeriodInterface | undefined, type: TypeForm | undefined) => {
        setIsModalOpened(open);
        setData({type: ((open && type) ? type : TypeForm.EMPTY), data: e})
    }


    let divMonths = [];
    let cptMonth = 1;

    let months = []
    for (cptMonth; cptMonth <= 12; cptMonth++) {
        months.push(<CalendarMonth key={cptMonth} month={cptMonth} year={year} data={dates}
                                    editable={props.editable} onClickEventDay={clickEventDay}/>)
    }

    divMonths.push(<div key={"divMonths"}
                        style={{display: "flex", flexDirection: "row", justifyContent: "safe center", flexWrap:"wrap"}}>{months}</div>)


    return (

        <FCContentModalFL form={data} onModalFormFinished={() => gestionModal(false, undefined, undefined)}>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",alignItems:"center"}}>
                <FCIconButton icon={faCaretLeft} onClickHandler={()=>setYear(year-1)} tooltip={""}/>
                <h2>{year}</h2>
                <FCIconButton icon={faCaretRight} onClickHandler={()=>setYear(year+1)} tooltip={""}/>
            </div>
            <div className={"divContentCalendar"}>
                {
                    loading && <FCSpinner size="small"/>
                }
                {
                    dates &&
                    <div style={{display: "flex", flexDirection: "column"}}>
                        {divMonths}
                        {props.editable && !isModalOpened &&
                            <FCFloatButton items={[]} icon={faPlus} positionBottom={"70px"}
                                           handlerOnClick={() => gestionModal(true, undefined, TypeForm.PERIOD)}/>
                        }
                    </div>
                }
                {
                    error &&
                    <div>
                        {error.message}
                        <FCAlertMsgErrorAPI error={error}/>
                    </div>
                }
            </div>
        </FCContentModalFL>
    );
}

export default ContentCalendar;