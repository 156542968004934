import React, {useContext, useState} from "react";
import "./ContentBilling.scss";
import {EventUpdatedContext} from "../../../store/EventUpdatedContext";
import {getIdWithToken} from "../../../utils/storage/Token";
import FCSpinner from "../../FCObjets/FCSpinner";
import StatusBilling, {TypeStatusBilling} from "../../../utils/Business/StatusBilling";
import {faBox, faEnvelope, faFilePdf, faPen, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import FCIconButton from "../../FCObjets/FCIconButton";
import {TypeForm} from "../../../types/EnumTypeForm";
import {BillingInterface} from "../../../service/Business/Interfaces/BillingInterface";
import {DeleteBilling, useFetchBilling} from "../../../service/Business/BillingService";
import FCAlertMsgErrorAPI from "../../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";
import {EnumTypeMail} from "../../../types/Business/EnumTypeMail";
import FCContentModalFL, {DataForm} from "../../FCObjets/Form/FCContentModalFL";
import FCTag from "../../FCObjets/FCTag";
import FCFloatButton from "../../FCObjets/FCFloatButton";
import FCModal from "../../FCObjets/FCModal";
import FCToggleSwitch from "../../FCObjets/FCToggleSwitch";

function ContentBilling() {

    let {cptUpdated, setUpdated} = useContext(EventUpdatedContext);
    const {data: billings, loading, error} = useFetchBilling(getIdWithToken(), cptUpdated);
    const [isModalOpened, setIsModalOpened] = useState(false)

    const [showBillingPayed, setShowBillingPayed] = useState(false);

    const [data, setData] = useState<DataForm>({
        type: TypeForm.EMPTY,
        data: undefined,
    });

    const [confirmIsOpen] = useState(false);
    const [confirmMessage] = useState("");


    // Permettant l'ouverture du modal
    const gestionModal = (open: boolean, e: BillingInterface | any | undefined, type: TypeForm | undefined) => {
        setIsModalOpened(open)
        let t = TypeForm.EMPTY;
        if (open && type) {
            t = type
        }

        let r: DataForm = {
            type: t,
            data: e
        };

        setData(r)
    }

    return (
        <FCContentModalFL form={data} onModalFormFinished={() => {
            gestionModal(false, undefined, undefined);
            setUpdated();
        }}>
            <div className={"content-page-billing"}>
                <div className={"div-title-billing"}>
                    <h1 className="content-heading">
                        Liste des Factures
                    </h1>
                    <FCToggleSwitch onToggle={(isChecked) => setShowBillingPayed(isChecked)} defaultChecked={false}
                                    label={"Payées/Terminées"}/>
                </div>
                {
                    !isModalOpened && <FCFloatButton items={[]} icon={faPlus} positionBottom={"70px"}
                                                     handlerOnClick={() => gestionModal(true, undefined, TypeForm.BILLING)}/>
                }
                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>Nom contrat</th>
                        <th>Numéro</th>
                        <th>Status</th>
                        <th>Tot. HT</th>
                        <th>Tot. TTC</th>
                        <th>Début</th>
                        <th>Fin</th>
                        <th>
                            <div className="row-content">
                                Actions
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        loading && <tr>
                            <td><FCSpinner size={"middle"}/></td>
                        </tr>
                    }
                    {
                        billings && billings.map((e: BillingInterface, index: React.Key) => {
                            let totalBillingExclTax = 0;
                            let totalBillingInclTax = 0;
                            if (e.billingItems) {
                                totalBillingExclTax = e.billingItems.reduce((accumulator, bit) => {
                                    return accumulator + bit.totalAmountExclTax
                                }, 0);

                                totalBillingInclTax = e.billingItems.reduce((accumulator, bit) => {
                                    return accumulator + bit.totalAmountInclTax
                                }, 0);
                            }

                            if (e.state === TypeStatusBilling.BILL_PAID && !showBillingPayed) {
                                return (<React.Fragment key={index}/>)
                            }

                            return (<tr key={index}>
                                <td data-label="Nom contrat">{e.businessDeal.name}</td>
                                <td data-label="Numéro">{e.number}</td>
                                <td data-label="Status"><FCTag value={StatusBilling.toString(e.state)}
                                                               color={StatusBilling.toColor(e.state)}
                                                               style={{fontWeight: "bold"}}/></td>
                                <td data-label="Tot. HT">{totalBillingExclTax} &euro;</td>
                                <td data-label="Tot. TTC">{totalBillingInclTax} &euro;</td>
                                <td data-label="Début">{new Date(e.begin).toLocaleDateString()}</td>
                                <td data-label="Fin">{new Date(e.end).toLocaleDateString()}</td>
                                <td data-label="Actions">
                                    <div className="row-content">
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            justifyItems: "left",
                                            width: "100%"
                                        }}>
                                            <FCIconButton icon={faPen} tooltip={"Modifier"} color={"green"}
                                                          onClickHandler={() => {
                                                              gestionModal(true, e, TypeForm.BILLING)
                                                          }}/>
                                            <FCIconButton icon={faTrash} tooltip={"Supprimer"} color={"red"}
                                                          onClickHandler={() => {
                                                              const onConfirm = () => {
                                                                  DeleteBilling(e).then(() => setUpdated())
                                                              }
                                                              const payload = {
                                                                  title: `Suppression de la facture ${e.number}`,
                                                                  msg: "Êtes-vous sûr de vouloir supprimer cette facture ?",
                                                                  onConfirm: onConfirm
                                                              }
                                                              gestionModal(true, payload, TypeForm.CONFIRM);
                                                          }}/>
                                            {
                                                e.state === TypeStatusBilling.DELIVERY_ORDER_SIGNED &&
                                                <FCIconButton icon={faBox} tooltip={"Archiver Facture"} color={"white"}
                                                              onClickHandler={() => gestionModal(true, e, TypeForm.UPLOAD_BILLING)}/>

                                            }
                                            {
                                                e.state === TypeStatusBilling.BILL_INIT &&
                                                <FCIconButton icon={faEnvelope} color={"orange"}
                                                              tooltip={"Envoyer Facture"}  onClickHandler={() => { e.typeMail = EnumTypeMail.BILLING ; return gestionModal(true, e, TypeForm.SEND_MAIL)}}  />
                                            }
                                            {
                                                (e.state === TypeStatusBilling.INITIALIZED || e.state === TypeStatusBilling.DELIVERY_ORDER_INIT) &&
                                                <FCIconButton icon={faFilePdf} tooltip={"Bon de livraison"} color={"white"}
                                                              onClickHandler={() => {
                                                                  window.open(`/pdf/bl/${e.id}`, '_blank')
                                                                  setTimeout(() => setUpdated(), 3000);
                                                              }}/>

                                            }
                                            {
                                                e.state === TypeStatusBilling.DELIVERY_ORDER_INIT &&
                                                 <FCIconButton icon={faEnvelope} color={"orange"}
                                                              tooltip={"Envoyer bon de livraison"}  onClickHandler={() => { e.typeMail = EnumTypeMail.BL ; return gestionModal(true, e, TypeForm.SEND_MAIL)}}  />

                                            }
                                            {
                                                e.state === TypeStatusBilling.DELIVERY_ORDER_SENT &&
                                                <FCIconButton icon={faBox} tooltip={"Archiver Bon de livraison"}
                                                              color={"white"}
                                                              onClickHandler={() => gestionModal(true, e, TypeForm.UPLOAD_BL)}/>
                                            }

                                        </div>
                                    </div>
                                </td>
                            </tr>)
                        })
                    }
                    {
                        (error) && <tr>
                            <td>
                                <div>{error.message}</div>
                                <FCAlertMsgErrorAPI error={error}/>
                            </td>
                        </tr>
                    }

                    </tbody>
                </table>

            </div>
            <FCModal isOpen={confirmIsOpen} onClose={function (): void {
                throw new Error("Function not implemented.");
            }}>
                {confirmMessage}

            </FCModal>
        </FCContentModalFL>
    );
}

export default ContentBilling;