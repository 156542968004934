import React from "react";
import "./FooterDashboard.scss"
import {useFetchVersion} from "../../../service/AdminService";
import {version} from "../../../../package.json"
import FCSpinner from "../../FCObjets/FCSpinner";
import FCAlertMsgErrorAPI from "../../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";

function FooterDashboard() {

    const {data: version_back, loading, error} = useFetchVersion();

    return (
        <div className={"footerDashboard"}>

            <div className={"titleFooter"}>FREE COMPTA &copy; 2023 Created by Yannick Marchetaux & Luc Sorignet</div>
                <div className={"versionFooter"}>
                    {
                        version_back &&
                        <div>
                            Version back: {version_back.version_back}
                        </div>
                    }

                    {
                        loading &&
                        <div>
                            Version back: <FCSpinner size={"small"}/>
                        </div>
                    }
                    {
                        error &&
                        <div>
                            <div>{error.message}</div>
                            <FCAlertMsgErrorAPI error={error}/>
                        </div>
                    }
                    <div style={{marginTop:"3px"}}>
                        Version front: {version}
                    </div>
                </div>
        </div>
    );
}

export default FooterDashboard;