export enum EnumTypeEvent {
    WEEKEND = 0,
    FERIE = 1,
    WORKED = 2,
    HOLIDAY = 3,
    CRITIC = 4,
    URSSAF = 5,
}

export interface EventsInterface {
    type: EnumTypeEvent;
    title: string;
    description: string;
    begin: string;
    end: string;
    period: PeriodInterface | undefined;
}

export interface CalendarInterface {
    listEvents: EventsInterface[];
}

export interface PeriodInterface {
    id: number;
    begin: string;
    end: string;
    type: number;
}

export interface DayInterface {
    monday: boolean,
    tuesday: boolean,
    wednesday: boolean,
    thursday: boolean,
    friday: boolean
}

export interface PeriodInterfaceTPInterface {
    id: number;
    begin: string;
    end: string;
    type: number;
    day: DayInterface
}
