import jwt from "jsonwebtoken";

const tokenKey = "authToken";

const getToken = (): string | null => {
    return window.localStorage.getItem(tokenKey);
};

const getIdWithToken = (): number => {
    const t = window.localStorage.getItem(tokenKey);
    if (t) {
        const r: any = jwt.decode(t.split(" ")[1]);
        if (r?.id_user) {
            return r?.id_user;
        }
    }
    return -1;
};

const getUserNameWithToken = (): string => {
    const t = window.localStorage.getItem(tokenKey);
    if (t) {
        const r: any = jwt.decode(t.split(" ")[1]);
        if (r?.username) {
            return r?.username;
        }
    }
    return "";
};

const isAdminWithToken = (): boolean => {
    const t = window.localStorage.getItem(tokenKey);
    if (t) {
        const r: any = jwt.decode(t.split(" ")[1]);
        if (r?.role) {
            return r?.role.toUpperCase() === "ADMIN";
        }
    }
    return false;
};

const setToken = (token: string): void => {
    window.localStorage.setItem(tokenKey, token);
};

const clearToken = (): void => {
    window.localStorage.removeItem(tokenKey);
};

const verifyToken = (): boolean => {
    const t = window.localStorage.getItem(tokenKey);
    if (t) {
        const decodeToken: any = jwt.decode(t.split(" ")[1]);
        if (!decodeToken) {
            return false;
        }
        if (decodeToken.exp < Date.now() / 1000) {
            clearToken();
        }
        return true;
    }
    return false;
};

export { getToken, getIdWithToken, getUserNameWithToken, isAdminWithToken, setToken, clearToken, verifyToken };
